import React from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import './index.scss';

export default function MinNavBar(props) {
  const { routes, setBurger } = props;
  const dataRoutes = routes || [];
  const { pathname } = useLocation();

  const setNavlinkActiveClasses = (paths) =>
    paths.some((path) => matchPath(path, pathname))
      ? 'panel-min-navbar_link panel-min-navbar_link-active'
      : 'panel-min-navbar_link';

  const isTypeLink = (route) => route?.type === 'link';

  const openNavbar = (route) => {
    if (!isTypeLink(route)) setBurger((prev) => !prev);
  };

  return (
    <div id="small-panel">
      <div className="panel-min-navbar">
        {dataRoutes?.map((route, key) => {
          if (['link', 'multi'].includes(route?.type))
            return (
              <NavLink
                key={key}
                className={setNavlinkActiveClasses(
                  isTypeLink(route) ? [route?.path] : [...route?.paths],
                )}
                to={isTypeLink(route) ? route?.path : pathname}
                onClick={() => openNavbar(route)}
              >
                <img className="m-0" src={route?.icon} alt={route?.name ?? ''} />
              </NavLink>
            );

          return null;
        })}
      </div>
    </div>
  );
}
