const FSENV = {
  auth: process.env.REACT_APP_URL_API_AUTH,
  tasks: process.env.REACT_APP_URL_API_TASK,
  approval: process.env.REACT_APP_URL_API_APPROVAL,
  fieldApp: process.env.REACT_APP_URL_API_FIELDAPP,
};

export function serviceUrl(service, version) {
  return FSENV[service] + `/api/${version}`;
}
