import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { urls } from 'entities/urls';
import { lazy, Suspense } from 'react';

const BloomCabinet = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomCabinet })),
);
const BloomChildAssessment = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomChildAssessment })),
);
const BloomChildComplete = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomChildComplete })),
);
const BloomChildDocument = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomChildDocument })),
);
const BloomChildFinance = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomChildFinance })),
);
const BloomChildPrincipal = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomChildPrincipal })),
);
const BloomDashboard = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomDashboard })),
);
const BloomDetails = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomDetails })),
);
const BloomForm = lazy(() => import('./pages').then((m) => ({ default: m.BloomForm })));
const BloomFormMore = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomFormMore })),
);
const BloomPayment = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomPayment })),
);
const BloomReview = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomReview })),
);
const BloomWelcome = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomWelcome })),
);
const BloomingdaleStage = lazy(() =>
  import('./pages').then((m) => ({ default: m.BloomingdaleStage })),
);
const ChildCustomStage = lazy(() =>
  import('./pages').then((m) => ({ default: m.ChildCustomStage })),
);

export const PortalRoutes = () => (
  <Routes>
    <Route element={<SuspenseLayout />}>
      <Route exact path={urls.parent.stage} element={<BloomingdaleStage />} />
      <Route exact path={urls.parent.welcome} element={<BloomWelcome />} />
      <Route exact path={urls.parent.cabinet} element={<BloomCabinet />} />
      <Route exact path={urls.parent.form} element={<BloomForm />} />
      <Route exact path={urls.parent.dashboard} element={<BloomDashboard />} />
      <Route exact path={urls.parent.details} element={<BloomDetails />} />
      <Route exact path={urls.parent.review} element={<BloomReview />} />
      <Route exact path={urls.parent.payment} element={<BloomPayment />} />
      <Route exact path={urls.parent.formMore} element={<BloomFormMore />} />
      <Route
        exact
        path={urls.parent.childAssessment}
        element={<BloomChildAssessment />}
      />
      <Route exact path={urls.parent.childPrincipal} element={<BloomChildPrincipal />} />
      <Route exact path={urls.parent.childDocument} element={<BloomChildDocument />} />
      <Route exact path={urls.parent.childFinance} element={<BloomChildFinance />} />
      <Route exact path={urls.parent.childComplete} element={<BloomChildComplete />} />
      <Route exact path={urls.parent.customStage} element={<ChildCustomStage />} />
      <Route exact path="/" element={<Navigate to={urls.parent.welcome} />} />
    </Route>
  </Routes>
);

function SuspenseLayout() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  );
}
