import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { analarrow, analarrow_down } from 'static/image';
import { calcPercent } from 'utils/utils';

const theme = createTheme({
  palette: {
    primary: { main: '#27C59A' },
  },
});

export default function FeeTermsCard({
  isSetTime = true,
  maxValue,
  index,
  item,
  termsArray = [],
  setTermsArray = () => {},
  handleSetFee = () => {},
  oldFee = {},
}) {
  const [value, setValue] = useState(item.dueFee);
  // const [isOpen, setOpen] = useState(false);
  const [admValue, setAdmValue] = useState(item.fee);

  // let dateValidation = new Date(value).toUTCString().substr(4, 12);
  // value.toString().substr(4, 6) + ',' + value.toString().substr(10, 6);

  const styles = {
    p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
    img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
    span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
  };
  useEffect(() => {
    setValue(item.dueFee);
    setAdmValue(item.fee);
  }, [item]);

  return (
    <div className={`flex ml-1.5 mb-${(index + 1 === maxValue && 16) || 5}`}>
      <div className="flex flex-col w-44">
        <p className="w-28" style={styles.p}>
          {item.name}
        </p>
        <div className="flex items-center">
          ₹{' '}
          <input
            className="w-12 text-black ml-1 border-none outline-none"
            type="number"
            value={admValue || undefined}
            onChange={(e) => {
              setTermsArray((prev) => {
                const list = [...prev];
                list[index].fee = +e.target.value;
                handleSetFee('terms', list);
                return list;
              });
              setAdmValue(e.target.value);
            }}
          />
          {(!!oldFee?.percentageChange || oldFee?.percentageChange === 0) && (
            <img
              src={
                calcPercent(oldFee.percentageChange, oldFee.fee, admValue) < 0
                  ? analarrow_down
                  : analarrow
              }
              style={styles.img}
              alt="percent"
              className={clsx(
                calcPercent(oldFee.percentageChange, oldFee.fee, admValue) < 0
                  ? 'rotate-180'
                  : '',
              )}
            />
          )}
          <span
            style={styles.span}
            className={clsx(
              (!!oldFee?.percentageChange || oldFee?.percentageChange === 0) &&
                calcPercent(oldFee.percentageChange, oldFee.fee, admValue) < 0
                ? 'text-[#ff4343]'
                : 'text-[#2ED775]',
            )}
          >
            {!!oldFee?.percentageChange || oldFee?.percentageChange === 0
              ? '' + calcPercent(oldFee.percentageChange, oldFee.fee, admValue) + '%'
              : '--'}
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-between w-40">
        <p className="w-28" style={styles.p}>
          Due Fee
        </p>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={!isSetTime}
              label="Custom input"
              value={value}
              onChange={(newValue) => {
                setTermsArray((prev) => {
                  const list = [...prev];
                  list[index].dueFee = newValue;
                  handleSetFee('terms', list);
                  return list;
                });
                setValue(newValue);
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
              // renderInput={({ inputRef, inputProps, InputProps }) => (
              //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
              //     <p
              //       className="flex items-center"
              //       ref={inputRef}
              //       onClick={() => {
              //         setOpen(!isOpen);
              //       }}
              //     >
              //       {dateValidation}
              //     </p>
              //     {InputProps?.endAdornment}
              //   </Box>
              // )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    </div>
  );
}

FeeTermsCard.propTypes = {
  num: PropTypes.number,
  admNum: PropTypes.number,
  maxValue: PropTypes.number,
};
