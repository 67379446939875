import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const StaticPicker = ({ pValue, psetValue, color }) => {
  const [value, setValue] = useState(new Date());

  const theme = createTheme({
    palette: {
      primary: { main: color || '#27C59A' },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          minDate={new Date(new Date().getFullYear() - 50, 1, 1)}
          maxDate={new Date(new Date().getFullYear() + 10, 12, 31)}
          value={pValue ? pValue : value}
          shouldDisableYear={null}
          views={['year', 'month', 'day']}
          onChange={(newValue) => {
            psetValue ? psetValue(newValue) : setValue(newValue);
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default StaticPicker;

StaticPicker.propTypes = {
  pValue: PropTypes.string,
  psetValue: PropTypes.func,
  color: PropTypes.string,
};
