import React, { useCallback, useState, useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import { NavLink, useNavigate } from 'react-router-dom';
import { Input } from 'components';
import { ToastMessageContext } from 'context/toast-context';
import { checkErrorSingleApi } from 'utils/check-error/api-error';
import { Logo } from '../../index.jsx';
import { forgot_password, invisible, visible } from '../image';
import '../UserLogin/index.scss';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function ResetPassword() {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [newPassword, setNewPassword] = useState('');
  const [reEnterNewPassword, setReEnterNewPassword] = useState('');
  const [show, setShow] = useState({});
  const navigate = useNavigate();
  const state = useStorage((state) => state.auth);
  // const { email, tenantId, codeVerify } = state;

  const handleShow = useCallback((key) => {
    setShow((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  const handleResetPassword = () => {
    if (!newPassword) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'Please enter the new password',
      });
      setIsShowToastMessage(true);
    } else if (newPassword !== reEnterNewPassword) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'The confirmation password is incorrect',
      });
      setIsShowToastMessage(true);
    } else {
      const data = JSON.stringify({
        email: state?.email,
        tenantId: state?.tenantId,
        newPassword: newPassword,
        codeVerify: state?.codeVerify,
      });

      // const config = {
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/reset-password`,
      //   data: data,
      // };

      // callApi(config)
      authApi
        .resetPassword(data)
        .then(function (response) {
          if (
            checkErrorSingleApi(
              response,
              setToastMessage,
              setIsShowToastMessage,
              'Reset Password',
            )
          ) {
            // navigate('/login/user');
            navigate('/login/type', { replace: true });
          } else {
            navigate('/login/forgot');
          }
        })
        .catch(function (error) {
          console.log(error);
          setToastMessage({
            status: 'error',
            title: 'Reset Password Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          // navigate('/login/user')
        });
    }
  };

  return (
    <div className="login account-type" style={{ background: '#E0ECE4' }}>
      <div className="m-auto">
        <NavLink className="mb-10 block" to="/">
          {/* <Logo fill={!account ? '#FFB226' : '#A5BBAC'} /> */}
          <Logo fill="#A5BBAC" />
        </NavLink>
        <Fade clear duration={300}>
          <div className="box">
            <h1 className="text-3xl font-black">Change Your Password</h1>
            <p
              className="text-second-gray font-normal text-base mt-1"
              style={{ width: '28rem' }}
            >
              Thank you for verifying your email.Please enter a new password
            </p>
            <div className="flex mt-5">
              <div style={{ width: '27.688rem' }}>
                <div className="relative">
                  <Input
                    name="password"
                    type={show.new ? 'text' : 'password'}
                    label="New Password"
                    value={newPassword}
                    customStyle={{
                      marginBottom: '1rem',
                      width: '100%',
                      height: '3rem',
                    }}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                  <img
                    className="absolute"
                    onClick={() => {
                      handleShow('new');
                    }}
                    src={show.new ? visible : invisible}
                    alt="show"
                    style={{
                      cursor: 'pointer',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                <div className="relative">
                  <Input
                    name="password"
                    type={show.reRender ? 'text' : 'password'}
                    label="Re-enter new Password"
                    value={reEnterNewPassword}
                    customStyle={{
                      marginBottom: '1rem',
                      width: '100%',
                      height: '3rem',
                    }}
                    onChange={(e) => {
                      setReEnterNewPassword(e.target.value);
                    }}
                  />
                  <img
                    className="absolute"
                    onClick={() => {
                      handleShow('reRender');
                    }}
                    src={show.reRender ? visible : invisible}
                    alt="show"
                    style={{
                      cursor: 'pointer',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                <button
                  className="box-submit"
                  onClick={() => {
                    handleResetPassword();
                  }}
                  style={{ marginTop: '1.5rem' }}
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
              <img src={forgot_password} alt="forgot_password" className="dodles" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
