import clsx from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApprovalIcon, NavApp, Notification, Options, Profile } from './sub-components';
import { icsearch, Bell, Grid, Clipboard } from 'static/image';
import './search.scss';
import { urls } from 'entities/urls';

export default function Search(props) {
  const {
    userLogin,
    numberNoti,
    setNumberNoti = () => {},
    unreadNotiFromFCM,
    isHaveNewNoti,
    setIsHaveNewNoti = () => {},
  } = props;
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    profile: false,
    notification: false,
    navapp: false,
    options: false,
  });

  return (
    <div className="header-search">
      <div className="header-search-input">
        <img className="header-search-input_img" src={icsearch} alt="search" />
        <input
          className="header-search-input_input"
          placeholder="Search by Name or Email"
        />
      </div>
      <div
        onClick={() => {
          setVisible({
            notification: false,
            profile: false,
            navapp: false,
            options: false,
          });
        }}
        className={clsx(
          'absolute w-screen h-screen top-0 -left-20',
          visible.profile || visible.navapp || visible.notification || visible.options
            ? ''
            : 'hidden',
        )}
      />
      <div className="header-search-nav">
        <div className="relative">
          <button
            onClick={() => {
              setVisible({
                notification: false,
                profile: false,
                options: !visible.options,
                navapp: false,
              });
            }}
            className="header-search-nav_button mr-1.5"
          >
            <span>+</span>
          </button>
          <Options visible={visible.options} />
        </div>

        <div>
          <button
            onClick={() => {
              setVisible({
                notification: false,
                profile: false,
                navapp: false,
                options: false,
              });
              navigate(urls.tasks);
            }}
            className="h-10 w-10 rounded-full flex items-center justify-center"
          >
            <Clipboard />
          </button>
        </div>

        <ApprovalIcon customClass="mx-2" />

        <div className="relative">
          <button
            onClick={() => {
              setVisible({
                notification: !visible.notification,
                profile: false,
                navapp: false,
                options: false,
              });
            }}
            className={`h-10 w-10 flex rounded-full items-center justify-center static ${
              visible.notification ? 'text-light-green' : 'text-main-blue'
            }`}
            style={
              visible.notification
                ? { background: 'rgba(0, 171, 85, 0.08)', borderRadius: '1rem' }
                : {}
            }
          >
            <Bell />
            {numberNoti !== 0 && (
              <label
                style={{
                  cursor: 'pointer',
                  color: '#fff',
                  fontSize: '10px',
                  borderRadius: '8px',
                  width: '1.25rem',
                  height: '1rem',
                  backgroundColor: 'rgb(183, 33, 54)',
                  bottom: '24px',
                  right: '0px',
                  position: 'absolute',
                  lineHeight: '1rem',
                }}
              >
                {numberNoti}
              </label>
            )}
          </button>
          <Notification
            visible={visible.notification}
            numberNoti={numberNoti}
            setNumberNoti={setNumberNoti}
            unreadNotiFromFCM={unreadNotiFromFCM}
            isHaveNewNoti={isHaveNewNoti}
            setIsHaveNewNoti={setIsHaveNewNoti}
          />
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setVisible({
                notification: false,
                profile: false,
                options: false,
                navapp: !visible.navapp,
              });
            }}
            className={`h-10 w-10 rounded-full flex items-center justify-center  ${
              visible.navapp ? 'text-light-green' : 'black'
            }`}
            style={visible.navapp ? { background: 'rgba(0, 171, 85, 0.08)' } : {}}
          >
            <Grid />
          </button>
          <NavApp
            visible={visible.navapp}
            onClosed={() => setVisible({ ...visible, navapp: false })}
          />
        </div>
        <div className="relative">
          <button
            className="h-10 w-10 rounded-full flex items-center justify-center ml-1.5 "
            onClick={() => {
              setVisible({
                notification: false,
                navapp: false,
                options: false,
                profile: !visible.profile,
              });
            }}
          >
            <img
              className="header-search-nav-profile_img"
              src={`${userLogin?.photoURL}`}
              alt="profile"
            />
          </button>
          <Profile
            visible={visible.profile}
            userLogin={userLogin}
            onClosed={() => setVisible({ ...visible, profile: false })}
          />
        </div>
      </div>
    </div>
  );
}
