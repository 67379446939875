import { Suspense, lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { urls } from 'entities/urls';

import UnderConstruction from '@core/pages/under-construction';

const CampusInformation = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.CampusInformation })),
);
const CampusDetail = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.CampusDetail })),
);
const ImportUser = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.ImportUser })),
);
const CreateCampus = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.CreateCampus })),
);
const CreateUser = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.CreateUser })),
);
const Edit = lazy(() => import('@users/pages').then((m) => ({ default: m.Edit })));
const RoleAndPermissions = lazy(() =>
  import('@users/pages').then((m) => ({ default: m.RoleAndPermissions })),
);

const Institutions = lazy(() =>
  import('./pages').then((m) => ({ default: m.Institutions })),
);
const Company = lazy(() => import('./pages').then((m) => ({ default: m.Company })));
const CompanySettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.CompanySettings })),
);
const GiikiAdminReports = lazy(() =>
  import('./pages').then((m) => ({ default: m.GiikiAdminReports })),
);
const UserList = lazy(() => import('./pages').then((m) => ({ default: m.UserList })));

export const SetupRoutes = ({ burger, isOpen, companyId }) => (
  <Routes>
    <Route element={<SuspenseLayout />}></Route>
    <Route path={urls.giiki.dashboard} element={<UnderConstruction burger={burger} />} />
    <Route path={urls.giiki.roles} element={<RoleAndPermissions burger={burger} />} />
    <Route
      exact
      path={urls.giiki.institutions.base}
      element={<Company burger={burger} isOpen={isOpen} />}
    />
    <Route
      exact
      path={urls.giiki.report.bug}
      element={<GiikiAdminReports burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.report.issue}
      element={<GiikiAdminReports burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.report.user}
      element={<GiikiAdminReports burger={burger} />}
    />
    <Route exact path={urls.giiki.users.base} element={<UserList burger={burger} />} />
    <Route
      exact
      path={urls.giiki.users.create}
      element={<CreateUser burger={burger} />}
    />
    <Route exact path={urls.giiki.users.edit} element={<Edit burger={burger} />} />
    <Route exact path={urls.giiki.setting} element={<UnderConstruction />} />
    <Route
      exact
      path={urls.giiki.institutions.company.base}
      element={
        companyId ? (
          <CompanySettings burger={burger} />
        ) : (
          <Navigate to={urls.giiki.institutions.base} />
        )
      }
    />
    <Route
      exact
      path={urls.giiki.institutions.company.campus.general}
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.institutions.company.campus.edit}
      element={<CampusDetail burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.institutions.company.campus.importUser}
      element={<ImportUser burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.institutions.company.campus.create}
      element={<CreateCampus burger={burger} />}
    />
    <Route
      exact
      path={urls.giiki.institutions.company.createUser}
      element={<CreateUser burger={burger} />}
    />
    <Route
      exact
      path="/giiki/institutions/:id"
      element={<Institutions burger={burger} isOpen={isOpen} />}
    />
    <Route exact path="*" element={<Navigate to={urls.giiki.dashboard} />} />
  </Routes>
);

function SuspenseLayout() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  );
}
