import React from 'react';
import PropTypes from 'prop-types';

export default function CompanyTabs(props) {
  const { types, setTypeState, typeState } = props;
  // const [typeDefault, setTypeDefaults] = useState(types && types[0])

  return types.map((type, key) => (
    <button
      className="pl-4 pr-4 pt-1 pb-1 font-medium text-sm rounded-lg transition-all none-after"
      style={
        // (typeState || typeDefault) === type
        typeState === type
          ? { background: '#F6F6F7', color: '#404EED' }
          : { color: '#919EAB' }
      }
      onClick={setTypeState ? () => setTypeState(type) : () => {}}
      key={key}
    >
      {type}
    </button>
  ));
}

CompanyTabs.propTypes = {
  types: PropTypes.array,
  setTypeState: PropTypes.func,
  typeState: PropTypes.string,
};
