import { useEffect, useMemo, useState, useContext, Suspense } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import './App.css';

import BasicPanel from '@core/components/BasicPanel';
import Header from '@core/pages/Header';
import Login from '@core/pages/Login';
import EnquiryForm from '@admissions/pages/enquiry-form/EnquiryForm';

import ToastMessage from 'components/toast-message/toast-message';
import { ToastMessageContext } from 'context/toast-context';
import { HOME_PATH } from './constant';
import authApi from 'api/auth';
import useStorage from 'store/storage';
import { getPanelTitleAndRoutes } from 'helper/panel';
import financeApi from 'api/finance';
import { checkErrorApiFetch } from 'utils/check-error/api-error';
import Loading from 'components/loading';

import { CoreRoutes } from 'modules/Core';
import { AcademicRoutes } from 'modules/Academic';
import { AdmissionRoutes } from 'modules/Admissions';
import { FinanceRoutes } from 'modules/Finance';
import { CommunicationsRoutes } from 'modules/Communication';
import { TaskAndApprovalRoutes } from 'modules/Tasks';
import { UserRoutes } from 'modules/Users';
import { SetupRoutes } from 'modules/GiikiSetup';
import { PortalRoutes } from 'modules/ParentPortal';
import { FieldAppRoutes } from './modules/FieldApp';

import { USER_ROLE } from 'utils/utils';

const PERIOD_24H = 24 * 60 * 60;
const PERIOD_30_DAYS = 30 * PERIOD_24H;

function App() {
  const [burger, setBurger] = useState(false);
  const [tenantId, setTenantId] = useState('giiki-test-1-a9hhl-2uj1p');
  const location = useLocation();
  const pathname = location.pathname;
  const {
    getAuth,
    getIsGiikiAdmin,
    getPeriod,
    getRememberMe,
    updateNavbarTitle,
    updateRoutes,
    updateCampuses,
    updateCurrentCampus,
    getCurrentCampus,
    updateYears,
    updateCurrentYear,
    updatePreviousYear,
    resetStorage,
  } = useStorage();
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowHomePage, setShowHomePage] = useState(false);
  const userLogin = getAuth();
  const campus = getCurrentCampus();
  const companyId = userLogin?.companyId;
  const isGiikiAdmin = getIsGiikiAdmin();
  const period = getPeriod();
  const rememberMe = getRememberMe();
  const navigate = useNavigate();
  const { toastMessage, setToastMessage, isShowToastMessage, setIsShowToastMessage } =
    useContext(ToastMessageContext);

  const fetchCampuses = async () => {
    setIsLoading(true);
    const res = await authApi.getListCampusUser({ isGetAll: true });
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      updateCampuses(res.data.data.objects);
      const noChangeCampus = res.data.data.objects.findIndex(
        (item) => item.id === campus?.id,
      );
      if (noChangeCampus === -1) {
        updateCurrentCampus(res.data.data.objects[0]);
      }
    } else {
      updateCampuses([]);
      updateCurrentCampus(null);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    // const isGiikiAdmin = userLogin?.roles?.some((role) => role.id === giikiAdminId);
    // updateIsGiikiAdmin(isGiikiAdmin);
    if (userLogin && !isGiikiAdmin) fetchCampuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin]);

  const fetchYears = async () => {
    const res = await financeApi.getListAcademic({ campusId: campus.id });
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      updateYears(res.data?.data?.objects);
      updateCurrentYear(res.data?.data?.objects?.[0]);
      if (res.data?.data?.objects?.[1]) {
        updatePreviousYear(res.data?.data?.objects?.[1]);
      } else {
        updatePreviousYear();
      }
    }
  };

  useEffect(() => {
    if (campus?.id) fetchYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campus]);

  const enquiryPathName = '/enquiry-form';
  let isEnquiryForm;
  // remember to shorten this conditions
  if (window?.location?.origin?.includes(process.env.REACT_APP_ALLOWED_ORIGIN)) {
    if (window.location.pathname === enquiryPathName) {
      isEnquiryForm = true;
    }
  }

  useEffect(() => {
    if (period) {
      let expired = PERIOD_24H;
      if (rememberMe) expired = PERIOD_30_DAYS;
      if (Math.floor(Date.now() / 1000) - period > expired) {
        resetStorage();
        navigate('/login/type');
      }
    }
    if (pathname === HOME_PATH) {
      updateNavbarTitle(null);
      updateRoutes([]);
    } else {
      const { title, routes: dataRoutes } = getPanelTitleAndRoutes(pathname);
      updateNavbarTitle(title);
      updateRoutes(dataRoutes);
    }
    setBurger(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isOpen = () => {
    if (location.pathname === HOME_PATH) return;
    setBurger(!burger);
  };

  useEffect(() => {
    const href = process.env.REACT_APP_HREF;
    if (window.location.hostname.startsWith(href)) {
      setShowHomePage(true);
      return;
    }
    const subdomain = window.location.hostname.split('.')[0];
    if (!!subdomain) {
      authApi
        .checkDomain({ domainName: subdomain })
        .then((results) => {
          const tenantId = results.data.data?.tenantId;
          if (tenantId) {
            setTenantId(tenantId);
          } else {
            setShowHomePage(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setShowHomePage(true);
        });
    } else {
      setShowHomePage(true);
    }
  }, []);

  const isShowParentPortal = useMemo(() => {
    return userLogin?.roles?.some((role) => role.name === USER_ROLE.PARENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClassCommon = () => {
    if (isShowParentPortal) return '';
    // if (location.pathname === HOME_PATH) return 'navbar-menu-close';
    // if (burger) return 'navbar-menu-open';
    // else return 'navbar-menu-close';
    return '';
  };

  if (isLoading)
    return (
      <div className="w-screen h-screen justify-center items-center flex">
        <Loading />
      </div>
    );

  if (isEnquiryForm) return <EnquiryForm />;
  if (isShowHomePage) return <></>;
  const toast = (
    <div
      onMouseMove={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <ToastMessage
        setIsShowToastMessage={setIsShowToastMessage}
        status={toastMessage.status}
        title={toastMessage.title}
        message={toastMessage.message}
        isHover={isHover}
        setIsHover={setIsHover}
      />
    </div>
  );
  if (!userLogin || !userLogin?.roles)
    return (
      <>
        {isShowToastMessage && toast}
        <Routes>
          <Route exact path="/" element={<Navigate to={'/login/type'} />} />
          <Route exact path="/login" element={<Navigate to={'/login/type'} />} />
          <Route
            exact
            path="/login/:id"
            element={<Login burger={burger} tenantId={tenantId} />}
          />
        </Routes>
      </>
    );
  if (isGiikiAdmin)
    return (
      <>
        {isShowToastMessage && toast}
        <BasicPanel burger={burger} setBurger={setBurger} isOpen={isOpen} />
        <Header burger={burger} isOpen={isOpen} />
        <SetupRoutes burger={burger} isOpen={isOpen} companyId={companyId} />
      </>
    );

  if (isShowParentPortal)
    return (
      <>
        {isShowToastMessage && toast}
        <PortalRoutes />
      </>
    );
  return (
    <>
      {isShowToastMessage && toast}
      {pathname !== HOME_PATH && (
        <>
          <BasicPanel burger={burger} setBurger={setBurger} isOpen={isOpen} />
          <Header burger={burger} isOpen={isOpen} />
        </>
      )}
      <div className={`max-w-[100vw] ${getClassCommon()}`}>
        <Routes>
          <Route element={<SuspenseLayout />}>
            {/* Core routes */}
            {CoreRoutes({ burger })}

            {/* Academic settings */}
            {AcademicRoutes({ burger })}

            {/* Admissions module */}
            {AdmissionRoutes({ burger })}

            {/* Finance module */}
            {FinanceRoutes({ burger })}

            {/* Field App */}
            {FieldAppRoutes({ burger })}

            {/* Communications & Notification */}
            {CommunicationsRoutes({ burger })}

            {/* Tasks & Approvals */}
            {TaskAndApprovalRoutes({ burger })}

            {/* User management module */}
            {UserRoutes({ burger, isOpen })}
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;

function SuspenseLayout() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  );
}
