import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// import { selectGroup } from 'entities/select-style';
import './selectgroup.scss';
// import { style } from '@mui/system';
// import { dropdown } from 'components/formbuilder/build/blocks/icon';

const SelectGroup = (props) => {
  const {
    customStyle,
    error,
    title,
    options,
    classNameGroup,
    classNameSelect,
    labelFix,
    placeholder,
    defaultValue,
    value,
    isDisabled,
    styles,
    isSearchable,
    formatOptionLabel,
    isMulti,
    onChange,
    classNamePrefix,
    // dropDown,
    components,
    filterOption,
  } = props;

  const handleOptions =
    isMulti && options ? [{ value: 'all', label: 'Select All' }, ...options] : options;

  // const DropdownIndicator = (props) => {
  //   return (
  //     <components.DropdownIndicator {...props}>{dropDown}</components.DropdownIndicator>
  //   );
  // };

  return (
    <div
      className={`select-group ${classNameGroup}`}
      style={{ ...customStyle, border: error ? '2px solid #ff4842' : '' }}
    >
      <span
        className={clsx(
          'select-group_title',
          isDisabled ? 'select-group_title-disabled' : '',
        )}
        style={{ ...labelFix, color: error ? '#ff4842' : '' }}
      >
        {title}
      </span>
      <Select
        className={classNameSelect}
        classNamePrefix={classNamePrefix || undefined}
        options={handleOptions}
        getOptionValue={(option) => option.info?.id || option?.value}
        // options={options}
        styles={styles}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value ? value : null}
        formatOptionLabel={formatOptionLabel}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        // onChange={onChange}
        onChange={(selected) => {
          isMulti && selected.length && selected.find((option) => option.value === 'all')
            ? onChange(options)
            : !isMulti
            ? onChange(selected || null)
            : onChange(selected);
        }}
        // components={dropDown ? { DropdownIndicator } : {}}
        components={components}
        filterOption={filterOption}
      />
    </div>
  );
};

SelectGroup.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  labelFix: PropTypes.object,
  placeholder: PropTypes.string,
  // defaultValue: PropTypes.object,
  // value: PropTypes.object,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
};

export default SelectGroup;
