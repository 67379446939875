export const BASE_GIIKI_URL = '/giiki';
export const BASE_CENTRAL_URL = '/central';
export const BASE_CENTRAL_SETUP_URL = `${BASE_CENTRAL_URL}/setup`;
export const BASE_CENTRAL_FINANCE_URL = `${BASE_CENTRAL_URL}/finance`;
export const BASE_CENTRAL_COMMUNICATION_URL = `${BASE_CENTRAL_URL}/communication-notification`;
export const BASE_CENTRAL_INTEGRATION_URL = `${BASE_CENTRAL_URL}/integration`;
export const BASE_CENTRAL_ADMISSION_URL = `${BASE_CENTRAL_URL}/admission`;
export const BASE_CENTRAL_APPROVAL_URL = `${BASE_CENTRAL_URL}/approval`;
export const BASE_CAMPUS_URL = '/campus';
export const BASE_CAMPUS_ADMISSION_URL = `${BASE_CAMPUS_URL}/admission`;
export const BASE_CAMPUS_SETTING_URL = `${BASE_CAMPUS_URL}/setting`;
export const BASE_CAMPUS_FINANCE_URL = `${BASE_CAMPUS_URL}/finance`;
export const BASE_PARENT_URL = '/parent';
export const urls = {
  home: '/home',
  approvals: '/approvals',
  createUser: '/create-user',
  importUser: '/import-user',
  createEnquiry: `${BASE_CAMPUS_ADMISSION_URL}/enquiry/create`,
  documentEdit: '/document-edit',
  myRequest: '/my-request',
  tasks: '/tasks',
  giiki: {
    home: `${BASE_GIIKI_URL}/home`,
    dashboard: `${BASE_GIIKI_URL}/dashboard`,
    // institutions: `${BASE_GIIKI_URL}/institutions`,
    institutions: {
      base: `${BASE_GIIKI_URL}/institutions`,
      company: {
        base: `${BASE_GIIKI_URL}/institutions/company`,
        createUser: `${BASE_GIIKI_URL}/institutions/company/create-user`,
        campus: {
          general: `${BASE_GIIKI_URL}/institutions/company/campus/general`,
          edit: `${BASE_GIIKI_URL}/institutions/company/campus/edit`,
          importUser: `${BASE_GIIKI_URL}/institutions/company/campus/import-user`,
          create: `${BASE_GIIKI_URL}/institutions/company/campus/create`,
        },
      },
    },
    users: {
      base: `${BASE_GIIKI_URL}/users`,
      create: `${BASE_GIIKI_URL}/users/create`,
      edit: `${BASE_GIIKI_URL}/users/edit`,
    },
    roles: `${BASE_GIIKI_URL}/roles`,
    report: {
      user: `${BASE_GIIKI_URL}/reports/user`,
      issue: `${BASE_GIIKI_URL}/reports/issue`,
      bug: `${BASE_GIIKI_URL}/reports/bug`,
    },
    setting: `${BASE_GIIKI_URL}/setting`,
  },
  central: {
    approval: {
      base: `${BASE_CENTRAL_APPROVAL_URL}/process`,
      settings: `${BASE_CENTRAL_APPROVAL_URL}/approval-settings`,
    },
    admission: {
      forms: `${BASE_CENTRAL_ADMISSION_URL}/forms`,
      formBuilder: `${BASE_CENTRAL_ADMISSION_URL}/form-builder`,
      documents: `${BASE_CENTRAL_ADMISSION_URL}/documents`,
      editDocument: `${BASE_CENTRAL_ADMISSION_URL}/edit-document`,
    },
    setup: {
      roles: `${BASE_CENTRAL_SETUP_URL}/roles`,
      users: {
        base: `${BASE_CENTRAL_SETUP_URL}/users`,
        create: `${BASE_CENTRAL_SETUP_URL}/users/create`,
        edit: `${BASE_CENTRAL_SETUP_URL}/users/edit`,
      },
      campuses: `${BASE_CENTRAL_SETUP_URL}/campuses`,
      campus: {
        general: `${BASE_CENTRAL_SETUP_URL}/campus/general`,
        edit: `${BASE_CENTRAL_SETUP_URL}/campus/edit`,
        importUser: `${BASE_CENTRAL_SETUP_URL}/campus/import-user`,
        create: `${BASE_CENTRAL_SETUP_URL}/campus/create`,
      },
    },
    finance: {
      feebooks: `${BASE_CENTRAL_FINANCE_URL}/feebooks`,
      feebookEdit: `${BASE_CENTRAL_FINANCE_URL}/feebook-edit`,
      scholarships: `${BASE_CENTRAL_FINANCE_URL}/scholarships`,
      cashierScreen: `${BASE_CENTRAL_FINANCE_URL}/cashier-screen`,
      cashierReports: `${BASE_CENTRAL_FINANCE_URL}/cashier-reports`,
      bankAccounts: `${BASE_CENTRAL_FINANCE_URL}/bank-accounts`,
      newBankAccount: `${BASE_CENTRAL_FINANCE_URL}/new-bank-account`,
    },
    communicationNotification: {
      communicationTemplates: `${BASE_CENTRAL_COMMUNICATION_URL}/communication-templates`,
      templateEdit: `${BASE_CENTRAL_COMMUNICATION_URL}/edit-templates`,
      notifications: `${BASE_CENTRAL_COMMUNICATION_URL}/notifications`,
    },
    integration: {
      whatsapps: `${BASE_CENTRAL_INTEGRATION_URL}/whatsapps`,
      phones: `${BASE_CENTRAL_INTEGRATION_URL}/phones`,
    },
  },
  campus: {
    admission: {
      dashboard: `${BASE_CAMPUS_ADMISSION_URL}/dashboard`,
      applications: `${BASE_CAMPUS_ADMISSION_URL}/application/application`,
      enquiriesOverview: `${BASE_CAMPUS_ADMISSION_URL}/enquiries-overview`,
      cashierPayments: `${BASE_CAMPUS_ADMISSION_URL}/cashier-payment`,
      setting: {
        distributions: `${BASE_CAMPUS_ADMISSION_URL}/setting/distributions`,
        teams: `${BASE_CAMPUS_ADMISSION_URL}/setting/admission-team`,
        documents: `${BASE_CAMPUS_ADMISSION_URL}/setting/documents`,
        editDocument: `${BASE_CAMPUS_ADMISSION_URL}/setting/edit-document`,
        approvals: `${BASE_CAMPUS_ADMISSION_URL}/setting/admission-approval`,
        forms: `${BASE_CAMPUS_ADMISSION_URL}/setting/forms`,
        formBuilder: `${BASE_CAMPUS_ADMISSION_URL}/setting/form-builder`,
        fields: `${BASE_CAMPUS_ADMISSION_URL}/setting/fields`,
        communicationTemplates: `${BASE_CAMPUS_ADMISSION_URL}/setting/communication-templates`,
        workflow: `${BASE_CAMPUS_ADMISSION_URL}/setting/workflow`,
      },
    },
    setting: {
      academicYears: `${BASE_CAMPUS_SETTING_URL}/academic-year/middle`,
      programClasses: `${BASE_CAMPUS_SETTING_URL}/program-and-classes`,
      users: {
        base: `${BASE_CAMPUS_SETTING_URL}/users`,
        create: `${BASE_CAMPUS_SETTING_URL}/users/create`,
        edit: `${BASE_CAMPUS_SETTING_URL}/users/edit`,
      },
      roles: `${BASE_CAMPUS_SETTING_URL}/roles`,
      keyRoles: `${BASE_CAMPUS_SETTING_URL}/key-roles`,
    },
    finance: {
      cashierPayment: `${BASE_CAMPUS_FINANCE_URL}/cashiers`,
      feebooks: `${BASE_CAMPUS_FINANCE_URL}/feebooks`,
      scholarships: `${BASE_CAMPUS_FINANCE_URL}/scholarships`,
      feebookEdit: `${BASE_CAMPUS_FINANCE_URL}/feebook-edit`,
    },
    goals: {},
    fieldAPP: {},
  },
  parent: {
    stage: `${BASE_PARENT_URL}/bloomingdale-stage`,
    welcome: `${BASE_PARENT_URL}/bloomingdale-welcome`,
    cabinet: `${BASE_PARENT_URL}/bloomingdale-cabinet`,
    form: `${BASE_PARENT_URL}/bloomingdale-form`,
    dashboard: `${BASE_PARENT_URL}/bloomingdale-dashboard`,
    details: `${BASE_PARENT_URL}/bloomingdale-details`,
    review: `${BASE_PARENT_URL}/bloomingdale-review`,
    payment: `${BASE_PARENT_URL}/bloomingdale-payment`,
    formMore: `${BASE_PARENT_URL}/bloomingdale-form-more`,
    childAssessment: `${BASE_PARENT_URL}/bloomingdale-child-assessment`,
    childPrincipal: `${BASE_PARENT_URL}/bloomingdale-child-principal`,
    childDocument: `${BASE_PARENT_URL}/bloomingdale-child-document`,
    childFinance: `${BASE_PARENT_URL}/bloomingdale-child-finance`,
    childComplete: `${BASE_PARENT_URL}/bloomingdale-child-complete`,
    customStage: `${BASE_PARENT_URL}/bloomingdale-child`,
  },
};
