/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';

import { memo, useCallback, useMemo } from 'react';
import { mainavatar } from 'static/image';
import { SelectGroup } from 'stories';
import Checkbox from 'stories/checkbox/checkbox';
import Chip from 'stories/chip/chip';
import { chat, email, phone, whatsapp } from './images';
import './notifications.scss';

const Notifications = (props) => {
  const {
    data,
    module,
    indexTitle,
    onChange,
    // setRefresh,
    roles,
    persons,
  } = props;
  return (
    <table className="notifications-table">
      <thead className="notifications-table_header notifications-header">
        <tr>
          <th>
            <ul className="notifications-header_icons">
              <li>
                <img src={email} alt="icon" className="notifications-header_icon" />
              </li>
              <li>
                <img src={phone} alt="icon" className="notifications-header_icon" />
              </li>
              <li>
                <img src={chat} alt="icon" className="notifications-header_icon" />
              </li>
              <li>
                <img src={whatsapp} alt="icon" className="notifications-header_icon" />
              </li>
            </ul>
          </th>
          <th className="notifications-header_title">Notification topics</th>
          <th className="notifications-header_title">Person roles</th>
        </tr>
      </thead>

      <tbody className="notifications-table_body">
        {data.map((item, index) => {
          const {
            defaultId,
            isSendEmail,
            isSendNotiApp,
            isSendPhone,
            isSendWhatsapp,
            topic,
            roleNames,
            userIds,
          } = item;
          const dataChips = (() => {
            if (userIds.length !== 0) {
              return [
                ...roleNames.map((item) => ({ value: item, label: item, isRole: true })),
                ...userIds.map((item) => {
                  return {
                    value: item?.id ?? item.value,
                    label: item?.name ?? item.label,
                    isRole: false,
                    photoURL: item?.photoURL ?? item.photoURL,
                  };
                }),
              ];
            }
            return [
              ...roleNames.map((item) => ({ value: item, label: item, isRole: true })),
            ];
          })();

          return (
            <tr key={defaultId}>
              <td className="notifications-checkbox-list">
                <Checkbox
                  value={isSendEmail}
                  onChange={() =>
                    onChange(module, indexTitle, index, 'isSendEmail', !isSendEmail)
                  }
                />
                <Checkbox
                  value={isSendPhone}
                  onChange={() =>
                    onChange(module, indexTitle, index, 'isSendPhone', !isSendPhone)
                  }
                />
                <Checkbox
                  value={isSendNotiApp}
                  onChange={() =>
                    onChange(module, indexTitle, index, 'isSendNotiApp', !isSendNotiApp)
                  }
                />
                <Checkbox
                  value={isSendWhatsapp}
                  onChange={() =>
                    onChange(module, indexTitle, index, 'isSendWhatsapp', !isSendWhatsapp)
                  }
                />
              </td>
              <td className="notifications-content">{topic}</td>
              <td className="notifications-chips pt-2 pb-2 relative">
                <ChipArea
                  module={module}
                  indexTitle={indexTitle}
                  index={index}
                  onChange={onChange}
                  dataChips={dataChips}
                  roles={roles}
                  persons={persons}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Notifications;

const ChipArea = (props) => {
  const { dataChips, roles, persons, onChange, module, indexTitle, index } = props;
  const [edit, setEdit] = useState(false);

  const options = useMemo(() => {
    return [
      ...Object.values(roles).map((val) => ({ value: val, label: val, isRole: true })),
      ...Object.keys(persons).map((key) => ({
        value: key,
        label: persons[key]?.name,
        photoURL: persons[key]?.photoURL,
        isRole: false,
      })),
    ];
  }, [roles, persons]);

  const handleChange = useCallback((e) => {
    const userIds = [];
    const roleNames = [];
    e.forEach(({ isRole, value, label, photoURL }) => {
      if (isRole) {
        roleNames.push(value);
      } else
        userIds.push({
          id: value,
          name: persons[value]?.name,
          photoURL: persons[value]?.photoURL,
        });
    });

    onChange(module, indexTitle, index, 'userIds', userIds);
    onChange(module, indexTitle, index, 'roleNames', roleNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      maxHeight: '5rem',
      overflowY: 'auto',
    }),
  };
  return (
    <>
      <div className={`mr-4 ${!edit ? 'max-h-14 overflow-y-auto' : ''} `}>
        {edit ? (
          <SelectGroup
            label=""
            isMulti={true}
            isSearchable={true}
            value={dataChips}
            options={options}
            formatOptionLabel={formatOptionLabelUser}
            onChange={handleChange}
            styles={customStyles}
          />
        ) : (
          <ShowChip chips={dataChips} />
        )}
      </div>
      <svg
        className="absolute top-0 right-0 cursor-pointer"
        onClick={() => {
          setEdit((prev) => !prev);
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="14"
        height="14"
      >
        <path
          fill="gray"
          d="M18.414 2a.997.997 0 0 0-.707.293l-2 2-1.414 1.414L3 17v4h4L21.707 6.293a.999.999 0 0 0 0-1.414l-2.586-2.586A.997.997 0 0 0 18.414 2zm0 2.414 1.172 1.172-1.293 1.293-1.172-1.172 1.293-1.293zm-2.707 2.707 1.172 1.172L6.172 19H5v-1.172L15.707 7.121z"
        />
      </svg>
    </>
  );
};

const formatOptionLabelUser = (props) => {
  const { label, photoURL, isRole } = props;
  return (
    <div className="block">
      <Chip
        label={label}
        image={!isRole ? photoURL || mainavatar : ''}
        symbol={isRole ? label[0] : ''}
        chipAvatarStyle={{ width: 18, height: 18 }}
        styleChip={'object-cover'}
        customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
      />
    </div>
  );
};

const ShowChip = memo((props) => {
  const { chips, setChips } = props;
  return (
    <ul className="flex items-center flex-wrap gap-[0.25rem] w-full">
      {chips.length !== 0 &&
        chips.map((item, key) => {
          const { label, isRole, photoURL } = item;
          return (
            <Chip
              key={label + key}
              image={photoURL ? photoURL : ''}
              symbol={!photoURL ? label?.[0] : ' '}
              label={label}
              customStyle={{ height: 24 }}
              chipAvatarStyle={{ width: 18, height: 18 }}
              styleChip={isRole ? 'text' : 'contained'}
              deleteChip={false}
              onDeleteClick={(e) => {
                setChips([
                  ...chips.filter((value, index) => {
                    return index !== key;
                  }),
                ]);
              }}
            />
          );
        })}
    </ul>
  );
});
ShowChip.displayName = 'ShowChip';
