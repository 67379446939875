import React from 'react';
import MinPanel from '../SmallPanel';
import NewBigPanel from '../NewBigPanel';
import clsx from 'clsx';

const BasicPanel = (props) => {
  const { isOpen, burger, setBurger } = props;

  return (
    <div className={clsx('z-50 transition-all-300')}>
      <NewBigPanel isOpen={isOpen} burger={burger} />
      <MinPanel setBurger={setBurger} />
    </div>
  );
};

BasicPanel.propTypes = {};

export default BasicPanel;
