import { createContext, useContext, useCallback, useEffect, useRef } from 'react';
import { getToken } from './auth';
import { useNotifications } from './notifications';
import { serviceUrl } from '../lib/serviceUrl';
import useStorage from 'store/storage';

export const AppSettings = createContext();

export function useSettings(group, fallback = {}, withSave = false) {
  const user = useStorage((state) => state.auth);
  const [settings, update] = useContext(AppSettings);
  const report = useNotifications();
  const fetched = useRef(settings.has(group));
  const fb = useRef(fallback);

  const saveSettings = useCallback(
    async (data) => {
      const id = encodeURIComponent(group);
      const query = new URLSearchParams();
      query.set('company', user.companyId);
      const apiBase = serviceUrl('fieldApp', 'v1');

      const rsp = await fetch(apiBase + `/settings/${id}?` + query, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          accessToken: await getToken(),
        },
        body: JSON.stringify(data),
      });
      if (!rsp.ok) throw new Error(rsp.statusText);
      const s = new Map(settings);
      s.set(group, data);
      update(s);
    },
    [group, settings, update, user],
  );

  useEffect(() => {
    if (!fetched.current) {
      const timer = setTimeout(async () => {
        fetched.current = true;
        try {
          const id = encodeURIComponent(group);
          const query = new URLSearchParams();
          query.set('company', user.companyId);
          const apiBase = serviceUrl('fieldApp', 'v1');

          const rsp = await fetch(apiBase + `/settings/${id}?` + query, {
            headers: { accesstoken: await getToken() },
          });
          if (!rsp.ok) throw new Error(rsp.status);
          const result = await rsp.json();
          if (result.success) {
            const s = new Map(settings);
            s.set(group, result.data);
            update(s);
          }
        } catch (err) {
          console.log(err);
          report('error', 'Failed to fetch settings - see log');
        }
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [group, settings, update, user, report]);

  const data = settings.get(group) || fb.current;
  return withSave ? [data, saveSettings] : data;
}

/**
 * Service types, for requests and checklists.
 */
export const services = {
  housekeeping: { title: 'Housekeeping', icon: 'cleaning-woman' },
  electrical: { title: 'Electrical', icon: 'test_equipment' },
  plumbing: { title: 'Plumbing', icon: 'clean_toilets' },
  it_networks: { title: 'IT & Networks', icon: 'repair_pc' },
  landscape: { title: 'Landscape', icon: 'clean_equipment' },
  vehicles: { title: 'Vehicles', icon: 'vehicle' },
  security: { title: 'Security', icon: 'guard' },
};

/**
 * Kinds of buildings, for map manager.
 */
export const buildingKinds = [
  ['grounds', { title: 'Ground Space', icon: 'landscaping', level: 0 }],
  ['academic', { title: 'Academic Block', icon: 'building', level: 0 }],
  ['floor', { title: 'Floor', icon: 'stairs', level: 1 }],
  ['room', { title: 'Room', icon: 'clean_blackboards', level: 2 }],
];

/**
 * Kinds of checklist actions, for checklist manager.
 */
export const actionTypes = {
  housekeeping: {
    clean_tables: { text: 'Clean tables', icon: 'clean_tables', duration: 15 },
    clean_windows: {
      text: 'Clean windows',
      icon: 'clean_windows',
      duration: 20,
    },
    laundry: { text: 'Collect laundry', icon: 'laundry', duration: 5 },
    refill_soap: { text: 'Refill soap', icon: 'refill_soap', duration: 10 },
    refill_paper: { text: 'Refill paper', icon: 'refill_paper', duration: 10 },
    clean_toilets: {
      text: 'Clean toilets',
      icon: 'clean_toilets',
      duration: 30,
    },
    empty_trash: { text: 'Empty trash', icon: 'empty_trash', duration: 5 },
    organize_shelf: {
      text: 'Organize shelf',
      icon: 'organize_shelf',
      duration: 5,
    },
    mop_floor: { text: 'Mop floor', icon: 'mop_floor', duration: 10 },
    dust: { text: 'Dust desks & tables', icon: 'dust', duration: 10 },
    sweep: { text: 'Sweep floor', icon: 'sweep', duration: 10 },
    remove_cobwebs: {
      text: 'Remove cobwebs',
      icon: 'remove_cobwebs',
      duration: 5,
    },
    clean_blackboards: {
      text: 'Clean blackboards',
      icon: 'clean_blackboards',
      duration: 5,
    },
    clean_beakers: {
      text: 'Rinse & dry beakers',
      icon: 'clean_beakers',
      duration: 10,
    },
    disinfect: { text: 'Disinfect', icon: 'disinfect', duration: 10 },
    vacuum: { text: 'Vacuum floors & mats', icon: 'vacuum', duration: 20 },
  },
  electrical: {
    replace_fuse: { text: 'Replace fuse', icon: 'replace_fuse', duration: 5 },
    test_equipment: {
      text: 'Test equipment',
      icon: 'test_equipment',
      duration: 20,
    },
    replace_lamp: { text: 'Replace lamp', icon: 'replace_lamp', duration: 5 },
  },
  plumbing: {
    fix_tap: { text: 'Fix tap', icon: 'fix_tap', duration: 20 },
    fix_leak: { text: 'Fix leaking pipe', icon: 'fix_leak', duration: 30 },
    repair_toilet: {
      text: 'Repair toilet flush',
      icon: 'repair_toilet',
      duration: 30,
    },
  },
  it_networks: {
    repair_pc: { text: 'Repair PC', icon: 'repair_pc', duration: 60 },
  },
  landscape: {
    clean_equipment: {
      text: 'Clean equipment',
      icon: 'clean_equipment',
      duration: 40,
    },
    cut_grass: { text: 'Cut grass', icon: 'cut_grass', duration: 60 },
    trim_hedge: { text: 'Trim hedge', icon: 'trim_hedge', duration: 60 },
    mark_pitch: { text: 'Mark pitches', icon: 'mark_pitch', duration: 60 },
    clear_litter: { text: 'Clear litter', icon: 'clear_litter', duration: 90 },
  },
  vehicles: {
    clean_vehicles: {
      text: 'Clean vehicles',
      icon: 'clean_vehicles',
      duration: 30,
    },
    check_fuel: { text: 'Check & re-fuel', icon: 'check_fuel', duration: 60 },
  },
  security: {
    repair_lock: { text: 'Repair lock', icon: 'repair_lock', duration: 20 },
    maintain_gate: {
      text: 'Maintain gate',
      icon: 'maintain_gate',
      duration: 20,
    },
    check_secure: {
      text: 'Patrol area & check secure',
      icon: 'check_secure',
      duration: 30,
    },
  },
};
