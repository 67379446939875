import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import './index.scss';
import MinNavBar from '../SmallNavbar';
import { HOME_PATH } from 'constant';
import { Giiki_Small_icon, mainavatar } from 'static/image';
import clsx from 'clsx';
import useStorage from 'store/storage';
import {
  BASE_CENTRAL_APPROVAL_URL,
  BASE_CENTRAL_FINANCE_URL,
  BASE_CENTRAL_SETUP_URL,
  BASE_GIIKI_URL,
} from 'entities/urls';

export default function MinPanel(props) {
  const { setBurger } = props;
  const location = useLocation();
  const { getRoutes, getCurrentCampus, getAuth } = useStorage();
  const routes = getRoutes();
  const campus = getCurrentCampus();
  const auth = getAuth();
  const pathname = location.pathname;
  const isNotShowUserCard =
    pathname.startsWith(BASE_GIIKI_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_SETUP_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_FINANCE_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_APPROVAL_URL + '/');

  // const fetchYear = async () => {
  //   const res = await financeApi.getListAcademic({ campusId: currentCampus?.id });

  //   if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
  //     const currentDate = new Date();
  //     const year = currentDate.getFullYear();
  //     const result = res.data.data.objects;

  //     let current
  //     let previous
  //     if (!currentAcademicYearName) {
  //       if (result && result.length !== 0) {
  //         const currentDate = new Date()
  //         const currentYear = currentDate.getFullYear()
  //         const startDate = new Date(`08-01-${currentYear} 00:00:00`)
  //         const endDate = new Date(`07-31-${currentYear + 1} 23:59:59`)

  //         if (startDate <= currentDate <= endDate) {
  //           for (let year of result) {
  //             if (year === `${currentYear}-${currentYear + 1}`)
  //               current = year
  //             if (year === `${currentYear - 1}-${currentYear}`)
  //               previous = year
  //           }
  //           if (!current) current = result[0]
  //           if (!previous) previous = ''
  //         } else {
  //           current = result?.[0]
  //           previous = result?.[1]
  //         }
  //         dispatch(setCurrentAcademicYearName(current));
  //         updateGiikiInfo(objFieldGiikiInfo.navbar, { currentAcademicYearName: current });
  //       }

  //       dispatch(setPreviousAcademicYearName(previous));
  //       dispatch(setListAcademicYearName(result));
  //       updateGiikiInfo(objFieldGiikiInfo.navbar, {
  //         listAcademicYearName: result,
  //         previousAcademicYearName: previous,
  //       });
  //     }
  //   }
  // }

  return (
    <div id="panel-min">
      <div className="panel-min">
        <Fade left duration={400}>
          <div className="panel-min-logo">
            <NavLink to="/home">
              <img
                className="panel-min-logo_img inline pt-[20%] w-1/3"
                src={Giiki_Small_icon}
                alt="logo"
              />
            </NavLink>
          </div>
          <div
            className={clsx(
              'panel-min-menu panel-min-menu_profile pl-0 mt-[5.5rem]',
              pathname === HOME_PATH ? 'hidden' : 'flex',
            )}
          >
            <img
              className="panel-min-menu_avatar"
              src={auth?.auth?.currentUser?.photoURL || mainavatar}
              alt="avatar"
            />
          </div>

          <div className="w-full" style={{ height: 'calc(100vh - 14rem)' }}>
            <MinNavBar routes={routes} setBurger={setBurger} />
          </div>
        </Fade>
        {!isNotShowUserCard ? (
          <div className="panel-min-menu panel-min-menu_campus">
            <img
              className="panel-min-menu_avatar_campus h-[2.5rem] aspect-square"
              src={campus?.logo}
              alt="logo"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
