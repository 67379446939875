import { urls } from 'entities/urls';
import { Navigate, Route } from 'react-router-dom';

import { ChangePassword } from './pages/Login/components';
import GiikiHome from './pages/Home';

export const CoreRoutes = ({ burger }) => (
  <>
    <Route exact path="/" element={<Navigate to={'/home'} />} />
    <Route exact path={urls.home} element={<GiikiHome burger={burger} />} />
    <Route
      exact
      path="/settings/change-password"
      element={<ChangePassword burger={burger} />}
    />
  </>
);
