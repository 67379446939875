import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({
  text,
  leftIcon,
  icon,
  buttonStyle,
  onClick,
  buttonSize,
  buttonType,
  buttonDisabled,
  customStyle,
  className,
  isStartButton,
  isOutlined,
  number,
  isCancel,
  styleIcon = '',
}) => {
  let addStyles = {};
  if (isStartButton) {
    addStyles = {
      ...addStyles,
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    };
  }
  if (isOutlined) {
    addStyles = {
      ...addStyles,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey',
    };
  }
  return (
    <button
      className={clsx(
        'btn',
        `btn-${buttonStyle}`,
        `btn-size-${buttonSize}`,
        className,
        isCancel ? 'cancel' : '',
      )}
      disabled={buttonDisabled}
      type={buttonType}
      style={{ ...customStyle, ...addStyles }}
      onClick={onClick}
    >
      {icon && <img className={styleIcon} src={icon} alt={icon} />}
      {number && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.8em',
            lineHeight: '1.1',
          }}
        >
          <p style={{ fontSize: '1.1em' }}>{number}</p>
          <p>{text}</p>
        </div>
      )}
      {!number && <p>{text}</p>}
      {leftIcon && <img className="ml-4 text-white" src={leftIcon} alt={leftIcon} />}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  leftIcon: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonType: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  customStyle: PropTypes.object,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  number: PropTypes.number,
  isStartButton: PropTypes.bool,
  isOutlined: PropTypes.bool,
};

Button.defaultProps = {
  text: 'main button',
  buttonStyle: 'contained',
  buttonSize: 'medium',
  buttonType: 'button',
  buttonDisabled: false,
  customStyle: {},
  isStartButton: false,
  isOutlined: false,
};

export default Button;
