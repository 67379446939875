import { useState } from 'react';
import {
  AppSettings,
  services,
  actionTypes,
  buildingKinds,
} from '@fieldapp/data/settings';

//---------------------------------------------------------------------------
// Module local data
//---------------------------------------------------------------------------

const defaultSettings = new Map([
  ['services', services],
  ['actionTypes', actionTypes],
  ['buildingKinds', buildingKinds],
]);

//---------------------------------------------------------------------------
// SettingsProvider
//---------------------------------------------------------------------------

export default function SettingsProvider({ children }) {
  const [settings, newSettings] = useState(defaultSettings);

  return (
    <AppSettings.Provider value={[settings, newSettings]}>
      {children}
    </AppSettings.Provider>
  );
}
