import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import './giiki-home.scss';
import {
  calls,
  graphstats,
} from '../Header/components/search/sub-components/nav-app/image';
import { ToastMessageContext } from 'context/toast-context';
import { campusLinkDefault, centralLinkDefault, comingSoonDefault } from 'entities/data';
import { checkErrorApiFetch } from 'utils/check-error/api-error';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function GiikiHome() {
  const [isCentralLayout, setIsCentralLayout] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [centralLink, setCentralLink] = useState(centralLinkDefault);
  const [campusLink, setCampusLink] = useState(campusLinkDefault);
  const { updateNavApp } = useStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const checkHomeMenu = async () => {
      try {
        const res = await authApi.checkHomeMenu();
        if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
          const data = res.data?.data;
          const isCentral = !!data?.isCentral;
          const centralLinkTemp = centralLinkDefault.map((item) => ({
            ...item,
            isActive: !!data?.listMenu?.[item.fieldCheck],
          }));
          const campusLinkTemp = campusLinkDefault.map((item) => ({
            ...item,
            isActive: !!data?.listMenu?.[item.fieldCheck],
          }));
          // Temporary override - SW
          campusLinkTemp.find((i) => i.label === 'Field APP').isActive = true;
          setIsCentralLayout(isCentral);
          setCentralLink(centralLinkTemp);
          setCampusLink(campusLinkTemp);
          updateNavApp({
            centralLink: centralLinkTemp,
            campusLink: campusLinkTemp,
            isCentralLayout: isCentral,
          });
        } else {
          setToastMessage({
            status: 'error',
            title: 'Fetch data failed',
            message: res.data?.message || '',
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Fetch data failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get list draft fail', error);
      }
    };
    checkHomeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (item) => {
    navigate(item.link);
  };

  return (
    <div className="giiki-world pl-36 1400px:pl-40">
      <Fade clear duration={300}>
        <div>
          <h1 className="text-4xl font-extrabold h-16">Giiki World</h1>
          {isCentralLayout && (
            <div>
              <p className="font-bold text-sm p-1 pr-5 mb-4">Central Apps</p>
              <div className="grid grid-cols-4 2xl:grid-cols-6 pl-4">
                {centralLink.map((item, key) =>
                  item.isActive ? (
                    <NavLink
                      className="flex flex-col transform hover:-translate-y-1 transition-all mb-10 items-center justify-center rounded-xl shadow-mini"
                      to={item.link}
                      key={key}
                      style={{ width: '9.938rem', height: '10.188rem' }}
                      onClick={() => onClick(item)}
                    >
                      <img
                        src={item.img}
                        alt="settings"
                        className={` ${
                          item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                        } h-14`}
                      />
                      <p className="whitespace-pre-wrap w-26 text-center text-sm mt-4 w-full px-2.5 break-words">
                        {item.label}
                      </p>
                    </NavLink>
                  ) : (
                    <div
                      className="relative flex flex-col cursor-not-allowed mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini"
                      key={key}
                      style={{ width: '9.938rem', height: '10.188rem' }}
                    >
                      <img
                        src={item.img}
                        alt="settings"
                        className={` ${
                          item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                        } h-14 opacity-50`}
                      />
                      <p className="whitespace-pre-wrap w-26 text-center text-sm mt-4 opacity-50 break-words">
                        {item.label}
                      </p>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}

          <p className="font-bold text-sm p-1 pr-5 mb-4">Campus Apps</p>
          <div className="grid grid-cols-4 2xl:grid-cols-6 pl-4">
            {campusLink.map((item, key) =>
              item.isActive ? (
                <NavLink
                  className="flex flex-col transform hover:-translate-y-1 transition-all mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini"
                  to={item.link}
                  key={key}
                  style={{ width: '9.938rem', height: '10.188rem' }}
                  onClick={() => onClick(item)}
                >
                  <img src={item.img} alt="settings" className="w-14 h-14" />
                  <p className="whitespace-pre w-26 text-center text-sm mt-4">
                    {item.label}
                  </p>
                </NavLink>
              ) : (
                <div
                  className="relative flex flex-col cursor-not-allowed mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini"
                  key={key}
                  style={{ width: '9.938rem', height: '10.188rem' }}
                >
                  <img
                    src={item.img}
                    alt="settings"
                    className={` ${
                      item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                    } h-14 opacity-50`}
                  />
                  <p className="overflow-ellipsis overflow-hidden whitespace-pre w-26 text-center text-sm mt-4 opacity-50">
                    {item.label}
                  </p>
                </div>
              ),
            )}
            {comingSoonDefault.map((item, key) => (
              <div
                className="relative flex flex-col cursor-not-allowed mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini"
                key={key}
                style={{ width: '9.938rem', height: '10.188rem' }}
              >
                <img
                  src={item.img}
                  alt="settings"
                  className={` ${
                    item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                  } h-14 opacity-50`}
                />
                <p className="overflow-ellipsis overflow-hidden whitespace-pre w-26 text-center text-sm mt-4 opacity-50">
                  {item.label}
                </p>
                <p className="absolute bottom-3.5 overflow-ellipsis overflow-hidden whitespace-pre w-26 text-center text-sm mt-4 opacity-50">
                  Coming soon...
                </p>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </div>
  );
}
