import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { graphstats, calls } from './image';
import { comingSoonDefault } from 'entities/data';
import useStorage from 'store/storage';

export default function NavApp(props) {
  const { visible } = props;
  const navApp = useStorage((state) => state.navApp);

  const onClickCampusLink = (item) => {};

  const onClickCentralLink = (item) => {};

  const renderSingle = (item, onClick) => {
    return (
      <React.Fragment key={item.label}>
        {item.isActive ? (
          <NavLink
            className="flex flex-col transform hover:-translate-y-1 transition-all mb-4 items-center overflow-ellipsis justify-center w-28 h-28 rounded-xl shadow-mini"
            to={item.link}
            onClick={() => onClick(item)}
          >
            <img
              src={item.img}
              alt="settings"
              className={` ${
                item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
              } h-14`}
            />
            <p className="overflow-ellipsis overflow-hidden whitespace-pre w-24 text-center text-sm mt-2">
              {item.label}
            </p>
          </NavLink>
        ) : (
          <div className="relative flex flex-col cursor-not-allowed mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini w-28 h-28">
            <img
              src={item.img}
              alt="settings"
              className={` ${
                item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
              } h-14 opacity-50`}
            />
            <p className="whitespace-pre-wrap w-26 text-center text-sm mt-4 opacity-50 break-words">
              {item.label}
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderCentralLayout = () => {
    return (
      <div>
        <p className="text-second-gray font-bold text-sm p-1 pr-5 pl-5 mb-4">
          Central Apps
        </p>
        <div className="grid grid-cols-3 ">
          {navApp?.centralLink?.map((item) => renderSingle(item, onClickCentralLink))}
        </div>
      </div>
    );
  };

  const renderCampusLayout = () => {
    return (
      <>
        <p className="text-second-gray font-bold text-sm p-1 pr-5 pl-5 mb-4">
          Campus Apps
        </p>
        <div className="grid grid-cols-3">
          {navApp?.campusLink?.map((item) => renderSingle(item, onClickCampusLink))}
        </div>
        {renderComingSoon()}
      </>
    );
  };

  const renderComingSoon = () => {
    return (
      <div className="grid grid-cols-3">
        {comingSoonDefault.map((item, key) => (
          <NavLink
            className="flex flex-col transition-all mb-4 items-center overflow-ellipsis justify-center w-28 h-28 rounded-xl shadow-mini cursor-not-allowed"
            to={item?.link || ''}
            key={key}
          >
            <img src={item.img} alt="settings" className="w-14 h-14 opacity-50" />
            <p className="overflow-ellipsis overflow-hidden whitespace-pre w-24 text-center text-sm mt-2 opacity-50">
              {item.label}
            </p>
            <p className="overflow-ellipsis overflow-hidden whitespace-pre w-26 text-center text-sm opacity-50">
              Coming soon...
            </p>
          </NavLink>
        ))}
      </div>
    );
  };

  return (
    <Zoom duration={300} when={visible}>
      <div
        className={clsx(
          'profile-box-card',
          'absolute pl-4 pt-4 pb-4 top-14 right-0 overflow-y-scroll w-[26rem] h-[20.063rem]',
          visible ? '' : 'hidden',
        )}
        style={{ width: '26rem', height: '20.063rem' }}
      >
        {navApp?.isCentralLayout && renderCentralLayout()}
        {renderCampusLayout()}
      </div>
    </Zoom>
  );
}

NavApp.propTypes = {
  visible: PropTypes.bool,
  onCLosed: PropTypes.func,
};
