import { phone } from 'phone';

// const validEmail = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$');

// const validPhone = new RegExp('^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$');

export function validEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}
export function validPhone(phoneNumber) {
  const test1 = phone(phoneNumber);
  if (!test1) {
    return false;
  }
  const country = test1?.countryIso3;
  return phone(phoneNumber, { country }).isValid;
}

export const validNumber = new RegExp('^[0-9]*$');

const isError = (obj, keyRequired) => {
  const result = keyRequired.some((item) => {
    if (item === 'phoneNumber') return !validPhone(obj[item]);

    if (item === 'email') return !validEmail(obj[item]);

    return !obj[item];
  });
  return result;
};

export const isErrorStudentInfo = (obj) => {
  const keyRequired = [
    'firstName',
    'lastName',
    'campus',
    'gender',
    'grade',
    'nationality',
    'phoneNumber',
    'year',
    'dateOfBirth',
    'email',
  ];
  return isError(obj, keyRequired);
};

export const isErrorParentInfo = (obj) => {
  const keyRequired = ['fullName', 'phoneNumber', 'email'];
  return isError(obj, keyRequired);
};
