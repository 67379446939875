import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { urls } from 'entities/urls';
import { campusSettingPath } from 'utils/utils';

const UnderConstruction = lazy(() => import('@core/pages/under-construction'));

const CreateCampus = lazy(() =>
  import('./pages').then((m) => ({ default: m.CreateCampus })),
);
const CampusInformation = lazy(() =>
  import('./pages').then((m) => ({ default: m.CampusInformation })),
);
const CampusDetail = lazy(() =>
  import('./pages').then((m) => ({ default: m.CampusDetail })),
);
const CampusListUsers = lazy(() =>
  import('./pages').then((m) => ({ default: m.CampusListUsers })),
);
const CreateUser = lazy(() => import('./pages').then((m) => ({ default: m.CreateUser })));
const Edit = lazy(() => import('./pages').then((m) => ({ default: m.Edit })));
const UserList = lazy(() => import('./pages').then((m) => ({ default: m.UserList })));
const RoleAndPermissions = lazy(() =>
  import('./pages').then((m) => ({ default: m.RoleAndPermissions })),
);
const CampusList = lazy(() => import('./pages').then((m) => ({ default: m.CampusList })));
const RoleAndPermissionsCentral = lazy(() =>
  import('./pages').then((m) => ({ default: m.RoleAndPermissionsCentral })),
);
const UserSettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.UserSettings })),
);
const ImportUser = lazy(() => import('./pages').then((m) => ({ default: m.ImportUser })));

export const UserRoutes = ({ burger, isOpen }) => (
  <>
    <Route
      exact
      path={urls.campus.setting.users.base}
      element={<CampusListUsers burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.setting.users.create}
      element={<CreateUser burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.setting.users.edit}
      element={<Edit burger={burger} />}
    />

    <Route exact path="/detail" element={<Navigate to="/detail/company" />} />
    <Route
      exact
      path="/detail/campus-information"
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.campus.general}
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.users.base}
      element={<UserList burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.users.create}
      element={<CreateUser burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.users.edit}
      element={<Edit burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.campus.edit}
      element={<CampusDetail burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.campus.create}
      element={<CreateCampus burger={burger} />}
    />

    <Route
      exact
      path={urls.campus.setting.roles}
      element={<RoleAndPermissions burger={burger} />}
    />
    <Route exact path={urls.campus.setting.keyRoles} element={<UnderConstruction />} />
    <Route
      exact
      path="/campus/campus-information"
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path="/central/setup/campuses"
      element={<CampusList burger={burger} isOpen={isOpen} />}
    />
    <Route
      exact
      path="/detail/campus-information"
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.campus.general}
      element={<CampusInformation burger={burger} />}
    />
    <Route
      exact
      path={urls.central.setup.roles}
      element={<RoleAndPermissionsCentral burger={burger} />}
    />
    <Route
      exact
      path="/settings/user-settings"
      element={<UserSettings burger={burger} />}
    />
    {/* <Route
      exact
      path={urls.createUser}
      element={<CreateUser burger={burger} />}
    /> */}
    <Route
      exact
      path={urls.central.setup.campus.importUser}
      element={<ImportUser burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.setting.users.edit}
      element={<Edit burger={burger} />}
    />
    <Route exact path={campusSettingPath} element={<UnderConstruction />} />
  </>
);
