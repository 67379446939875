import { Route } from 'react-router-dom';
import { urls } from 'entities/urls';
import { campusAdmissionPath } from 'utils/utils';
import { lazy } from 'react';

const Forms = lazy(() => import('@core/pages/forms'));
const FormBuilder = lazy(() => import('@core/pages/form-builder'));
const UnderConstruction = lazy(() => import('@core/pages/under-construction'));
const Distribution = lazy(() => import('@core/pages/distribution'));

const Dashboard = lazy(() => import('@finance/pages/dashboard'));

const AdmissionsWorld = lazy(() =>
  import('./pages').then((m) => ({ default: m.AdmissionsWorld })),
);
const Stages = lazy(() => import('./pages').then((m) => ({ default: m.Stages })));
const Managment = lazy(() => import('./pages').then((m) => ({ default: m.Managment })));
const AdmissionDocumentSettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.AdmissionDocumentSettings })),
);
const AdmissionsOverview = lazy(() =>
  import('./pages').then((m) => ({ default: m.AdmissionsOverview })),
);
const Application = lazy(() =>
  import('./pages').then((m) => ({ default: m.Application })),
);
const AdmissionApprovals = lazy(() =>
  import('./pages').then((m) => ({ default: m.AdmissionApprovals })),
);
const AdmissionTeamSettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.AdmissionTeamSettings })),
);
const EditDocumentSettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.EditDocumentSettings })),
);
const NewEnquiry = lazy(() => import('./pages').then((m) => ({ default: m.NewEnquiry })));

export const AdmissionRoutes = ({ burger }) => (
  <>
    <Route path={urls.central.admission.forms} element={<Forms burger={burger} />} />
    <Route
      exact
      path={urls.central.admission.formBuilder}
      element={<FormBuilder burger={burger} />}
    />
    <Route
      exact
      path={urls.central.admission.documents}
      element={<AdmissionDocumentSettings burger={burger} />}
    />
    <Route
      exact
      path={urls.central.admission.editDocument}
      element={<AdmissionDocumentSettings burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.dashboard}
      element={<Dashboard burger={burger} />}
    />
    <Route
      exact
      path="/worlds/admissions"
      element={<AdmissionsWorld burger={burger} />}
    />
    <Route exact path="/managment" element={<Managment burger={burger} />} />
    <Route
      exact
      path={urls.campus.admission.setting.workflow}
      element={<Stages burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.enquiriesOverview}
      element={<AdmissionsOverview burger={burger} />}
    />
    <Route
      path={urls.campus.admission.applications}
      element={<Application burger={burger} />}
    />
    <Route
      exact
      path={urls.central.approval.settings}
      element={<AdmissionApprovals burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.teams}
      element={<AdmissionTeamSettings burger={burger} />}
    />
    <Route
      exact
      path={urls.documentEdit}
      element={<EditDocumentSettings burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.distributions}
      element={<Distribution burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.forms}
      element={<Forms burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.formBuilder}
      element={<FormBuilder burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.documents}
      element={<AdmissionDocumentSettings burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.admission.setting.editDocument}
      element={<EditDocumentSettings burger={burger} />}
    />
    <Route exact path={urls.createEnquiry} element={<NewEnquiry burger={burger} />} />

    <Route exact path={campusAdmissionPath} element={<UnderConstruction />} />
    <Route exact path="/campus/admission-settings" element={<UnderConstruction />} />
  </>
);
