/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import * as _ from 'lodash';
import { useCallback, useContext, useRef, useState } from 'react';
import Accordion from 'components/accordion/accordion';
import Notifications from 'components/notifications/notifications';
import { CompanyTabs } from 'stories';
import {
  checkErrorMultipleApiFetch,
  checkErrorSingleApi,
} from 'utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { useEffect } from 'react';
import { NavLinks } from '@core/pages/Header/components/index';
import './notifcations.scss';
import notificationApi from 'api/notification';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function NotificationsPage({ burger }) {
  const [roles, setRoles] = useState({});
  const [persons, setPersons] = useState({});

  const [refresh, setRefresh] = useState(0);
  const [modulesTab, setModulesTab] = useState([]);
  const [moduleTabCurrent, setModuleTabCurrent] = useState('');
  const [dataOfModule, setDataOfModule] = useState([]);
  const [dataTasksAndRemindersSettings, setDataTasksAndRemindersSettings] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const refOriginData = useRef();
  const campus = useStorage((state) => state.currentCampus);
  const auth = useStorage((state) => state.auth);

  useEffect(() => {
    const setting = notificationApi.getListNotificationSetting({
      campusId: campus?.isCentral ? undefined : campus?.id,
    });
    const roles = authApi.getAllRoleOfCompanyOrCampus({
      campusId: campus?.isCentral ? undefined : campus?.id,
    });
    const persons = authApi.getUserOfCompanyOrCampus({
      excludeRoleNames: 'Student;Parent',
      campusId: campus?.isCentral ? undefined : campus?.id,
    });
    Promise.all([setting, roles, persons])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          setDataTasksAndRemindersSettings(res[0].data.data);
          refOriginData.current = JSON.stringify(res[0].data.data);

          const listModule = [];
          res[0].data.data.forEach((item) => {
            listModule.push(item.module);
          });
          setModulesTab(listModule);
          setModuleTabCurrent(listModule[0]);
          setDataOfModule(res[0].data.data[0]);

          const listRole = {};
          res[1].data.data.forEach(({ id, name }) => {
            listRole[id] = name;
          });
          setRoles(listRole);

          const listPerson = {};
          res[2].data.data.objects.forEach(({ id, name, photoURL }) => {
            listPerson[id] = { name, photoURL };
          });
          setPersons(listPerson);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Notification Page Error', error);
      });
  }, [refresh, campus]);

  const handleSave = useCallback(() => {
    const dataPost = [];
    const origin = JSON.parse(refOriginData.current);

    for (let i = 0; i < dataTasksAndRemindersSettings.length; i++) {
      for (let j = 0; j < dataTasksAndRemindersSettings[i].data.length; j++) {
        for (let k = 0; k < dataTasksAndRemindersSettings[i].data[j].length; k++) {
          const objOrigin = origin[i].data[j][k];
          const objUpdate = dataTasksAndRemindersSettings[i].data[j][k];
          if (!_.isEqual(objOrigin, objUpdate)) {
            const {
              id,
              defaultId,
              isSendNotiApp,
              isSendEmail,
              isSendPhone,
              isSendWhatsapp,
              roleNames,
              userIds,
            } = objUpdate;
            const data = {
              defaultId,
              isSendNotiApp,
              isSendEmail,
              isSendPhone,
              isSendWhatsapp,
              roleNames,
              userIds: ((userIds) => {
                if (userIds.length === 0) return [];
                return userIds.map((item) => {
                  return item.id;
                });
              })(userIds),
            };
            if (id) data.id = id;
            dataPost.push(data);
          }
        }
      }
    }

    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/setting`,
    //   params: { campusId: auth?.campusId || campus?.id },
    //   data: { data: dataPost },
    // })
    notificationApi
      .notificationSetting({ campusId: auth?.campusId || campus?.id }, { data: dataPost })
      .then((res) => {
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
          setToastMessage({
            status: 'success',
            title: 'Setting Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setRefresh((prev) => prev + 1);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Setting Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [dataTasksAndRemindersSettings]);

  const handleCancel = () => {
    const dataOrigin = JSON.parse(refOriginData.current);
    setDataTasksAndRemindersSettings(dataOrigin);
    setDataOfModule(dataOrigin.find((item) => item.module === moduleTabCurrent));
  };

  const handleChangeModuleTab = useCallback(
    (module) => {
      setModuleTabCurrent(module);
      setDataOfModule(
        dataTasksAndRemindersSettings.find((item) => item.module === module),
      );
    },
    [moduleTabCurrent, dataTasksAndRemindersSettings],
  );

  const handleChangeDetail = useCallback((module, title, row, key, value) => {
    setDataTasksAndRemindersSettings((prev) => {
      for (const dataOfModule of prev) {
        if (dataOfModule.module === module) {
          dataOfModule.data[title][row][key] = value;
          break;
        }
      }
      return [...prev];
    });
  }, []);

  return (
    <div
      className={clsx(
        'test pr-24 pl-24 transition-all-300',
        burger ? '1400px:pl-72' : '1400px:pl-32',
      )}
    >
      <NavLinks urls={dataLinks.notificationsLinks} />
      <h1 className="namepage">Notifcations</h1>
      <div className="mb-6">
        <CompanyTabs
          types={modulesTab}
          setTypeState={handleChangeModuleTab}
          typeState={moduleTabCurrent}
        />
      </div>
      <div className="notifications__items mb-12">
        {dataOfModule?.data?.length.length !== 0 &&
          dataOfModule?.data?.map((listSettings, index) => {
            return (
              <Accordion key={listSettings + index} title={listSettings[0].title}>
                <Notifications
                  module={moduleTabCurrent}
                  indexTitle={index}
                  data={listSettings}
                  roles={roles}
                  persons={persons}
                  setRefresh={setRefresh}
                  onChange={handleChangeDetail}
                />
              </Accordion>
            );
          })}
      </div>
      <div
        className={clsx(
          'flex justify-center mb-4 fixed bottom-2 w-[70rem] left-1/2 -translate-x-1/2',
          // burger ? 'w-[76%]' : 'w-full',
        )}
      >
        <button
          type="button"
          className="w-1/4 border-solid border px-[7%] py-1 rounded mr-4"
          style={{
            borderColor: 'blue',
            color: 'blue',
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="w-1/4 text-white rounded"
          style={{ backgroundColor: 'blue' }}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
}
