import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { icmenu } from 'static/image';
import Search from './components/search/search';
import './header.scss';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../Login/components/UserLogin/firebase';
import { ToastMessageContext } from 'context/toast-context';
import useStorage from 'store/storage';

const Header = (props) => {
  const { isOpen, burger } = props;
  const [numberNoti, setNumberNoti] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [unreadNotiFromFCM, setUnreadNotiFromFCM] = useState();
  const [isHaveNewNoti, setIsHaveNewNoti] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect((props) => {
    const broadcast = new BroadcastChannel('background-message');
    broadcast.onmessage = (event) => {
      // setNumberNoti(numberNoti + 1);
      setIsHaveNewNoti(true);
      setToastMessage({
        status: 'info',
        title: 'Notification',
        message: 'You have a new notification 1',
      });
      setIsShowToastMessage(true);
    };

    try {
      onMessage(messaging, (payload) => {
        // console.log('message force background', payload);
        // setNumberNoti(numberNoti + 1);
        // setUnreadNotiFromFCM(payload);
        setIsHaveNewNoti((prev) => !prev);
        setToastMessage({
          status: 'info',
          title: 'Notification',
          message: 'You have a new notification 2',
        });
        setIsShowToastMessage(true);
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userLogin = useStorage((state) => state.auth);

  return (
    <header className={clsx('header', burger ? 'header-open' : 'header-closed')}>
      <div>
        <button className="header-burger" onClick={isOpen}>
          <img src={icmenu} alt="burger" className="w-6 h-6" />
        </button>
      </div>
      <Search
        userLogin={userLogin}
        numberNoti={numberNoti}
        setNumberNoti={setNumberNoti}
        unreadNotiFromFCM={unreadNotiFromFCM}
        isHaveNewNoti={isHaveNewNoti}
        setIsHaveNewNoti={setIsHaveNewNoti}
      />
    </header>
  );
};

Header.propTypes = {
  isOpen: PropTypes.func,
  burger: PropTypes.bool,
  header: PropTypes.string,
  setNavbar: PropTypes.func,
  numberNoti: PropTypes.number,
  setNumberNoti: PropTypes.func,
};

export default Header;
