import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { iconbase } from 'static/image';
import './prodcard.scss';

const ProdCard = (props) => {
  const {
    firstHeader,
    secondHeader,
    firstName,
    secondName,
    modal,
    opinio,
    access,
    setModalAddProgram,
    programId,
  } = props;

  const getValueMin = () => {
    if (secondName) return Number(secondName.split(' ')[0].split('-')[0]);
    else return 0;
  };
  const getValueMax = () => {
    if (secondName) return Number(secondName.split(' ')[0].split('-')[1]);
    else return 30;
  };
  const getColorBars = () => {
    let ageNumber = parseInt(secondName.split('-')[1], 10);
    if (ageNumber >= 0 && ageNumber <= 5)
      return [
        'bg-red-500',
        'bg-button-disable',
        'bg-button-disable',
        'bg-button-disable',
      ];
    if (ageNumber > 5 && ageNumber <= 10)
      return ['bg-[#f2994a]', 'bg-[#f2994a]', 'bg-button-disable', 'bg-button-disable'];
    if (ageNumber > 10 && ageNumber <= 15)
      return ['bg-[#fbd036]', 'bg-[#fbd036]', 'bg-[#fbd036]', 'bg-button-disable'];
    if (ageNumber > 15)
      return ['bg-[#4eda7e]', 'bg-[#4eda7e]', 'bg-[#4eda7e]', 'bg-[#4eda7e]'];
  };

  return (
    <div
      className={clsx('prog-card h-24', !access && 'prog-card_shadow')}
      style={access && opinio < 1 ? { bottom: 5 } : {}}
    >
      <div className=" ml-4">
        <div className="prog-card-row">
          <div
            className="prog-card-row_text w-full"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '0px',
            }}
          >
            {firstHeader && <h5 className="">{firstHeader}</h5>}
            {firstName && <p className="mr-2">{firstName}</p>}
          </div>
        </div>
        <div className="prog-card-row w-full pt-0">
          <div className="prog-card-row_text">
            {secondHeader && <h5 className="">{secondHeader}</h5>}
            {secondName && !access && <p className="">{secondName}</p>}
          </div>
          {secondName && (
            <div className=" mr-10" style={{ alignSelf: 'end' }}>
              {getColorBars().map((color, index) => {
                return (
                  <span
                    key={index}
                    className={`w-1 h-3 ml-1 ${color} inline-block`}
                  ></span>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          setModalAddProgram({
            id: programId,
            isOpen: true,
            state: {
              min: getValueMin(),
              max: getValueMax(),
            },
            firstName,
          });
        }}
      >
        {modal && (
          <img className="prog-card-row_optn mt-2" src={iconbase} alt="options" />
        )}
      </div>
    </div>
  );
};

ProdCard.propTypes = {
  firstHeader: PropTypes.string,
  secondHeader: PropTypes.string,
  firstName: PropTypes.string,
  secondName: PropTypes.string,
  modal: PropTypes.bool,
  opinio: PropTypes.number,
};

ProdCard.defaultProps = {
  opinio: 1,
  modal: false,
  access: false,
  crumbs: false,
};

export default ProdCard;

// const Crumbs = () => {
//   return (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M9.3335 3.33333C9.3335 4.06971 8.73654 4.66667 8.00016 4.66667C7.26378 4.66667 6.66683 4.06971 6.66683 3.33333C6.66683 2.59695 7.26378 2 8.00016 2C8.73654 2 9.3335 2.59695 9.3335 3.33333ZM9.3335 8C9.3335 8.73638 8.73654 9.33333 8.00016 9.33333C7.26378 9.33333 6.66683 8.73638 6.66683 8C6.66683 7.26362 7.26378 6.66667 8.00016 6.66667C8.73654 6.66667 9.3335 7.26362 9.3335 8ZM8.00016 14C8.73654 14 9.3335 13.403 9.3335 12.6667C9.3335 11.9303 8.73654 11.3333 8.00016 11.3333C7.26378 11.3333 6.66683 11.9303 6.66683 12.6667C6.66683 13.403 7.26378 14 8.00016 14Z"
//         fill="#637381"
//       />
//     </svg>
//   );
// };
