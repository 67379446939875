import { lazy } from 'react';
import { urls } from 'entities/urls';
import { Route } from 'react-router-dom';
import { campusFinancePath, centralPath } from 'utils/utils';

const UnderConstruction = lazy(() => import('@core/pages/under-construction'));

const FinanceDashboard = lazy(() =>
  import('./pages').then((m) => ({ default: m.FinanceDashboard })),
);
const FeebookEdit = lazy(() =>
  import('./pages').then((m) => ({ default: m.FeebookEdit })),
);
const FeeBooks = lazy(() => import('./pages').then((m) => ({ default: m.FeeBooks })));
const BankAccount = lazy(() =>
  import('./pages').then((m) => ({ default: m.BankAccount })),
);
const NewBankAccount = lazy(() =>
  import('./pages').then((m) => ({ default: m.NewBankAccount })),
);
const CashierPayment = lazy(() =>
  import('./pages').then((m) => ({ default: m.CashierPayment })),
);

export const FinanceRoutes = ({ burger }) => (
  <>
    {/* Central Finance */}
    <Route
      exact
      path="/finance/dashboard"
      element={<FinanceDashboard burger={burger} />}
    />
    <Route exact path={urls.central.finance.feebooks} element={<UnderConstruction />} />
    <Route
      exact
      path={urls.central.finance.feebookEdit}
      element={<FeebookEdit burger={burger} />}
    />
    <Route
      exact
      path={urls.central.finance.scholarships}
      element={<UnderConstruction />}
    />
    <Route
      exact
      path={urls.central.finance.bankAccounts}
      element={<BankAccount burger={burger} />}
    />
    <Route
      exact
      path={urls.central.finance.newBankAccount}
      element={<NewBankAccount burger={burger} />}
    />

    {/* Campus Finance */}
    <Route exact path={campusFinancePath} element={<UnderConstruction />} />
    <Route
      exact
      path={urls.campus.finance.feebooks}
      element={<FeeBooks burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.finance.feebookEdit}
      element={<FeebookEdit burger={burger} />}
    />

    {/* Cashier screens */}
    <Route
      exact
      path={urls.campus.admission.cashierPayments}
      element={<CashierPayment burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.finance.cashierPayment}
      element={<CashierPayment burger={burger} />}
    />
    <Route
      exact
      path={urls.central.finance.cashierScreen}
      element={<UnderConstruction />}
    />
    <Route
      exact
      path={urls.central.finance.cashierReports}
      element={<UnderConstruction />}
    />

    <Route exact path={campusFinancePath} element={<UnderConstruction />} />
    <Route exact path={centralPath.finance} element={<UnderConstruction />} />
    <Route exact path={centralPath.setting} element={<UnderConstruction />} />
  </>
);
