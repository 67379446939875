export const fieldAppRoutes = [
  {
    type: 'span',
    name: 'CENTRAL',
    path: '/fieldApp/central',
  },
  {
    path: '/fieldApp/',
    icon: '/fieldApp/icons/ic_house.svg',
    type: 'link',
    name: 'Home',
  },
  {
    path: '/fieldApp/dashboard',
    icon: '/fieldApp/icons/ic_dashboard.svg',
    type: 'link',
    name: 'Dashboard',
  },
  {
    path: '/fieldApp/locations',
    icon: '/fieldApp/icons/ic_map_location.svg',
    type: 'link',
    name: 'Locations',
  },
  {
    path: '/fieldApp/checklists',
    icon: '/fieldApp/icons/ic_checklist.svg',
    type: 'link',
    name: 'Checklists',
  },
  {
    path: '/fieldApp/shifts',
    icon: '/fieldApp/icons/ic_calendar.svg',
    type: 'link',
    name: 'Shifts',
  },
  {
    type: 'span',
    name: 'CAMPUS',
    path: '/fieldApp/campus',
  },
  {
    path: '/fieldApp/requests',
    icon: '/fieldApp/icons/ic_mop_floor.svg',
    type: 'link',
    name: 'Requests',
  },
  {
    path: '/fieldApp/my-tasks',
    icon: '/fieldApp/icons/ic_circle_check.svg',
    type: 'link',
    name: 'My Tasks',
  },
];
