export const navbarName = {
  giiki: 'Giiki Admin',
  task: 'Task',
  central: 'Central Admin',
  campus: 'Campus Admin',
  admission: 'Admission',
  // centralCampus: 'central-campus',
  finance: 'Finance Head',
  fieldApp: 'Field Services',
  others: 'others',
};

export const headerName = {
  giiki: 'Giiki Admin',
  task: 'Task',
  central: 'Central Admin',
  campus: 'Campus Admin',
  // centralCampus: 'Central-campus',
  finance: 'Finance Head',
  admission: 'Admission',
  // centralCampus: 'central-campus',
  others: 'others',
};

export const roleName = {
  central: 'Central Admin',
};

export const moduleName = {
  task: 'task',
  admission: 'admission',
  central: 'central',
  campus: 'campus',
  finance: 'finance',
};

export const campusAdmissionPath = '/campus/admission/dashboard';
export const campusSettingPath = '/campus/campus-settings';
export const campusFinancePath = '/campus/campus-finance';

export const centralPath = {
  admission: '/central-admin/admission',
  setup: '/central-admin/campuses',
  finance: '/central-admin/finance',
  approval: '/approvals',
  setting: '/central-admin/communication-and-setting',
};

export const DEFAULT_PAGE_SIZE = 5;

export const DEFAULT_PAGE_START = 0;

export const USER_ROLE = {
  GIIKI_ADMIN: 'Giiki Admin',
  CENTRAL_ADMIN: 'Central Admin',
  CAMPUS_ADMIN: 'Campus Admin',
  FINANCE_HEAD: 'Finance Head',
  ADMISSIONS_STAFF: 'Admissions Staff',
  ACCOUNTANT: 'Accountant',
  ADMISSIONS_COUNSELOR: 'Admissions Counselor',
  ADMISSIONS_HEAD: 'Admissions Head',
  GIIKI_BUDDY: 'Giiki Buddy',
  IT_ADMIN: 'IT Admin',
  PRINCIPAL: 'Principal',
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
};

export const CURRENT_ACADEMIC_YEAR_NAME = 'currentAcademicYearName';
export const PREVIOUS_ACADEMIC_YEAR_NAME = 'previousAcademicYearName';

export function camelToKebabCase(str) {
  if (!str) return str;
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function kebabToCamelCase(str) {
  if (!str) return str;
  return str.replace(/-./g, (x) => x[1].toUpperCase());
}

export function stringToCamelCase(str) {
  if (!str) return '';
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
}

export function sortObj(obj) {
  if (typeof obj !== 'object') return {};
  if (Object.keys(obj).length === 0) return {};
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

export function transformInfoToObj(data) {
  const objInfo = {};
  data.forEach((item) => {
    const { label, value, variationOf } = item;
    if (label) objInfo[stringToCamelCase(label)] = { value, type: variationOf };
  });
  return objInfo;
}

export function transformDataExportSingle(data) {
  const result = {};
  data.forEach((item) => {
    const { label, value, variationOf, isHidden } = item;
    if (label) {
      result[label] = { value, type: variationOf, isHidden };
    }
  });
  return result;
}
export function transformDataExport(data) {
  let result;
  if (data.length === 0) return null;
  if (Array.isArray(data[0])) {
    result = [];
    data.forEach((item) => {
      result.push(transformDataExportSingle(item));
    });
  } else {
    result = transformDataExportSingle(data);
  }
  return result;
}

export const deleteKeysInObject = ({ obj, listKey }) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) return {};
  if (listKey.length === 0) return obj;

  listKey.forEach((key) => delete obj[key]);
  return obj;
};

export const deleteKeysInObjectExclude = ({ obj, listKey }) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) return {};
  if (listKey.length === 0) return obj;
  const keyObj = Object.keys(obj);

  for (let key of keyObj) {
    if (!listKey.includes(key)) delete obj[key];
  }

  return obj;
};

export const APPLICATION_STEP = {
  New: 'New',
  Application: 'Application',
  Signature: 'Signature',
  Exam: 'Exam',
  'Principal Approval': 'Principal',
  'Document Verification': 'Document',
  'Fee Payment': 'Fee Payment',
  Complete: 'Complete',
  Failed: 'Failed',
};

export const languages = [
  { label: 'Afar ', value: 'Afar ' },
  { label: 'Abkhaz ', value: 'Abkhaz ' },
  { label: 'Avestan ', value: 'Avestan ' },
  { label: 'Afrikaans ', value: 'Afrikaans ' },
  { label: 'Akan ', value: 'Akan ' },
  { label: 'Amharic ', value: 'Amharic ' },
  { label: 'Aragonese ', value: 'Aragonese ' },
  { label: 'Arabic ', value: 'Arabic ' },
  { label: 'Assamese ', value: 'Assamese ' },
  { label: 'Avaric ', value: 'Avaric ' },
  { label: 'Aymara ', value: 'Aymara ' },
  { label: 'Azerbaijani ', value: 'Azerbaijani ' },
  { label: 'South Azerbaijani ', value: 'South Azerbaijani ' },
  { label: 'Bashkir ', value: 'Bashkir ' },
  { label: 'Belarusian ', value: 'Belarusian ' },
  { label: 'Bulgarian ', value: 'Bulgarian ' },
  { label: 'Bihari ', value: 'Bihari ' },
  { label: 'Bislama ', value: 'Bislama ' },
  { label: 'Bambara ', value: 'Bambara ' },
  { label: 'Bengali; Bangla ', value: 'Bengali; Bangla ' },
  {
    label: 'Tibetan Standard, Tibetan, Central ',
    value: 'Tibetan Standard, Tibetan, Central ',
  },
  { label: 'Breton ', value: 'Breton ' },
  { label: 'Bosnian ', value: 'Bosnian ' },
  { label: 'Catalan; Valencian ', value: 'Catalan; Valencian ' },
  { label: 'Chechen ', value: 'Chechen ' },
  { label: 'Chamorro ', value: 'Chamorro ' },
  { label: 'Corsican ', value: 'Corsican ' },
  { label: 'Cree ', value: 'Cree ' },
  { label: 'Czech ', value: 'Czech ' },
  {
    label: 'Old Church Slavonic, Church Slavonic, Old Bulgarian ',
    value: 'Old Church Slavonic, Church Slavonic, Old Bulgarian ',
  },
  { label: 'Chuvash ', value: 'Chuvash ' },
  { label: 'Welsh ', value: 'Welsh ' },
  { label: 'Danish ', value: 'Danish ' },
  { label: 'German ', value: 'German ' },
  { label: 'Divehi; Dhivehi; Maldivian; ', value: 'Divehi; Dhivehi; Maldivian; ' },
  { label: 'Dzongkha ', value: 'Dzongkha ' },
  { label: 'Ewe ', value: 'Ewe ' },
  { label: 'Greek, Modern ', value: 'Greek, Modern ' },
  { label: 'English ', value: 'English ' },
  { label: 'Esperanto ', value: 'Esperanto ' },
  { label: 'Spanish; Castilian ', value: 'Spanish; Castilian ' },
  { label: 'Estonian ', value: 'Estonian ' },
  { label: 'Basque ', value: 'Basque ' },
  { label: 'Persian (Farsi) ', value: 'Persian (Farsi) ' },
  { label: 'Fula; Fulah; Pulaar; Pular ', value: 'Fula; Fulah; Pulaar; Pular ' },
  { label: 'Finnish ', value: 'Finnish ' },
  { label: 'Fijian ', value: 'Fijian ' },
  { label: 'Faroese ', value: 'Faroese ' },
  { label: 'French ', value: 'French ' },
  { label: 'Western Frisian ', value: 'Western Frisian ' },
  { label: 'Irish ', value: 'Irish ' },
  { label: 'Scottish Gaelic; Gaelic ', value: 'Scottish Gaelic; Gaelic ' },
  { label: 'Galician ', value: 'Galician ' },
  { label: 'Guaraní ', value: 'Guaraní ' },
  { label: 'Gujarati ', value: 'Gujarati ' },
  { label: 'Manx ', value: 'Manx ' },
  { label: 'Hausa ', value: 'Hausa ' },
  { label: 'Hebrew (modern) ', value: 'Hebrew (modern) ' },
  { label: 'Hindi ', value: 'Hindi ' },
  { label: 'Hiri Motu ', value: 'Hiri Motu ' },
  { label: 'Croatian ', value: 'Croatian ' },
  { label: 'Haitian; Haitian Creole ', value: 'Haitian; Haitian Creole ' },
  { label: 'Hungarian ', value: 'Hungarian ' },
  { label: 'Armenian ', value: 'Armenian ' },
  { label: 'Herero ', value: 'Herero ' },
  { label: 'Interlingua ', value: 'Interlingua ' },
  { label: 'Indonesian ', value: 'Indonesian ' },
  { label: 'Interlingue ', value: 'Interlingue ' },
  { label: 'Igbo ', value: 'Igbo ' },
  { label: 'Nuosu ', value: 'Nuosu ' },
  { label: 'Inupiaq ', value: 'Inupiaq ' },
  { label: 'Ido ', value: 'Ido ' },
  { label: 'Icelandic ', value: 'Icelandic ' },
  { label: 'Italian ', value: 'Italian ' },
  { label: 'Inuktitut ', value: 'Inuktitut ' },
  { label: 'Japanese ', value: 'Japanese ' },
  { label: 'Javanese ', value: 'Javanese ' },
  { label: 'Georgian ', value: 'Georgian ' },
  { label: 'Kongo ', value: 'Kongo ' },
  { label: 'Kikuyu, Gikuyu ', value: 'Kikuyu, Gikuyu ' },
  { label: 'Kwanyama, Kuanyama ', value: 'Kwanyama, Kuanyama ' },
  { label: 'Kazakh ', value: 'Kazakh ' },
  { label: 'Kalaallisut, Greenlandic ', value: 'Kalaallisut, Greenlandic ' },
  { label: 'Khmer ', value: 'Khmer ' },
  { label: 'Kannada ', value: 'Kannada ' },
  { label: 'Korean ', value: 'Korean ' },
  { label: 'Kanuri ', value: 'Kanuri ' },
  { label: 'Kashmiri ', value: 'Kashmiri ' },
  { label: 'Kurdish ', value: 'Kurdish ' },
  { label: 'Komi ', value: 'Komi ' },
  { label: 'Cornish ', value: 'Cornish ' },
  { label: 'Kyrgyz ', value: 'Kyrgyz ' },
  { label: 'Latin ', value: 'Latin ' },
  { label: 'Luxembourgish, Letzeburgesch ', value: 'Luxembourgish, Letzeburgesch ' },
  { label: 'Ganda ', value: 'Ganda ' },
  {
    label: 'Limburgish, Limburgan, Limburger ',
    value: 'Limburgish, Limburgan, Limburger ',
  },
  { label: 'Lingala ', value: 'Lingala ' },
  { label: 'Lao ', value: 'Lao ' },
  { label: 'Lithuanian ', value: 'Lithuanian ' },
  { label: 'Luba-Katanga ', value: 'Luba-Katanga ' },
  { label: 'Latvian ', value: 'Latvian ' },
  { label: 'Malagasy ', value: 'Malagasy ' },
  { label: 'Marshallese ', value: 'Marshallese ' },
  { label: 'Māori ', value: 'Māori ' },
  { label: 'Macedonian ', value: 'Macedonian ' },
  { label: 'Malayalam ', value: 'Malayalam ' },
  { label: 'Mongolian ', value: 'Mongolian ' },
  { label: 'Marathi (Marāṭhī) ', value: 'Marathi (Marāṭhī) ' },
  { label: 'Malay ', value: 'Malay ' },
  { label: 'Maltese ', value: 'Maltese ' },
  { label: 'Burmese ', value: 'Burmese ' },
  { label: 'Nauru ', value: 'Nauru ' },
  { label: 'Norwegian Bokmål ', value: 'Norwegian Bokmål ' },
  { label: 'North Ndebele ', value: 'North Ndebele ' },
  { label: 'Nepali ', value: 'Nepali ' },
  { label: 'Ndonga ', value: 'Ndonga ' },
  { label: 'Dutch ', value: 'Dutch ' },
  { label: 'Norwegian Nynorsk ', value: 'Norwegian Nynorsk ' },
  { label: 'Norwegian ', value: 'Norwegian ' },
  { label: 'South Ndebele ', value: 'South Ndebele ' },
  { label: 'Navajo, Navaho ', value: 'Navajo, Navaho ' },
  { label: 'Chichewa; Chewa; Nyanja ', value: 'Chichewa; Chewa; Nyanja ' },
  { label: 'Occitan ', value: 'Occitan ' },
  { label: 'Ojibwe, Ojibwa ', value: 'Ojibwe, Ojibwa ' },
  { label: 'Oromo ', value: 'Oromo ' },
  { label: 'Oriya ', value: 'Oriya ' },
  { label: 'Ossetian, Ossetic ', value: 'Ossetian, Ossetic ' },
  { label: 'Panjabi, Punjabi ', value: 'Panjabi, Punjabi ' },
  { label: 'Pāli ', value: 'Pāli ' },
  { label: 'Polish ', value: 'Polish ' },
  { label: 'Pashto, Pushto ', value: 'Pashto, Pushto ' },
  { label: 'Portuguese ', value: 'Portuguese ' },
  { label: 'Quechua ', value: 'Quechua ' },
  { label: 'Romansh ', value: 'Romansh ' },
  { label: 'Kirundi ', value: 'Kirundi ' },
  { label: 'Romanian ', value: 'Romanian ' },
  { label: 'Russian ', value: 'Russian ' },
  { label: 'Kinyarwanda ', value: 'Kinyarwanda ' },
  { label: 'Sanskrit (Saṁskṛta) ', value: 'Sanskrit (Saṁskṛta) ' },
  { label: 'Sardinian ', value: 'Sardinian ' },
  { label: 'Sindhi ', value: 'Sindhi ' },
  { label: 'Northern Sami ', value: 'Northern Sami ' },
  { label: 'Sango ', value: 'Sango ' },
  { label: 'Sinhala, Sinhalese ', value: 'Sinhala, Sinhalese ' },
  { label: 'Slovak ', value: 'Slovak ' },
  { label: 'Slovene ', value: 'Slovene ' },
  { label: 'Samoan ', value: 'Samoan ' },
  { label: 'Shona ', value: 'Shona ' },
  { label: 'Somali ', value: 'Somali ' },
  { label: 'Albanian ', value: 'Albanian ' },
  { label: 'Serbian ', value: 'Serbian ' },
  { label: 'Swati ', value: 'Swati ' },
  { label: 'Southern Sotho ', value: 'Southern Sotho ' },
  { label: 'Sundanese ', value: 'Sundanese ' },
  { label: 'Swedish ', value: 'Swedish ' },
  { label: 'Swahili ', value: 'Swahili ' },
  { label: 'Tamil ', value: 'Tamil ' },
  { label: 'Telugu ', value: 'Telugu ' },
  { label: 'Tajik ', value: 'Tajik ' },
  { label: 'Thai ', value: 'Thai ' },
  { label: 'Tigrinya ', value: 'Tigrinya ' },
  { label: 'Turkmen ', value: 'Turkmen ' },
  { label: 'Tagalog ', value: 'Tagalog ' },
  { label: 'Tswana ', value: 'Tswana ' },
  { label: 'Tonga (Tonga Islands) ', value: 'Tonga (Tonga Islands) ' },
  { label: 'Turkish ', value: 'Turkish ' },
  { label: 'Tsonga ', value: 'Tsonga ' },
  { label: 'Tatar ', value: 'Tatar ' },
  { label: 'Twi ', value: 'Twi ' },
  { label: 'Tahitian ', value: 'Tahitian ' },
  { label: 'Uyghur, Uighur ', value: 'Uyghur, Uighur ' },
  { label: 'Ukrainian ', value: 'Ukrainian ' },
  { label: 'Urdu ', value: 'Urdu ' },
  { label: 'Uzbek ', value: 'Uzbek ' },
  { label: 'Venda ', value: 'Venda ' },
  { label: 'Vietnamese ', value: 'Vietnamese ' },
  { label: 'Volapük ', value: 'Volapük ' },
  { label: 'Walloon ', value: 'Walloon ' },
  { label: 'Wolof ', value: 'Wolof ' },
  { label: 'Xhosa ', value: 'Xhosa ' },
  { label: 'Yiddish ', value: 'Yiddish ' },
  { label: 'Yoruba ', value: 'Yoruba ' },
  { label: 'Zhuang, Chuang ', value: 'Zhuang, Chuang ' },
  { label: 'Chinese ', value: 'Chinese ' },
  { label: 'Zulu ', value: 'Zulu ' },
];

export const nationalities = [
  { label: 'Afghan', value: 'Afghan' },
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Algerian', value: 'Algerian' },
  { label: 'American', value: 'American' },
  { label: 'Andorran', value: 'Andorran' },
  { label: 'Angolan', value: 'Angolan' },
  { label: 'Anguillan', value: 'Anguillan' },
  { label: 'Argentine', value: 'Argentine' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Australian', value: 'Australian' },
  { label: 'Austrian', value: 'Austrian' },
  { label: 'Azerbaijani', value: 'Azerbaijani' },
  { label: 'Bahamian', value: 'Bahamian' },
  { label: 'Bahraini', value: 'Bahraini' },
  { label: 'Bangladeshi', value: 'Bangladeshi' },
  { label: 'Barbadian', value: 'Barbadian' },
  { label: 'Belarusian', value: 'Belarusian' },
  { label: 'Belgian', value: 'Belgian' },
  { label: 'Belizean', value: 'Belizean' },
  { label: 'Beninese', value: 'Beninese' },
  { label: 'Bermudian', value: 'Bermudian' },
  { label: 'Bhutanese', value: 'Bhutanese' },
  { label: 'Bolivian', value: 'Bolivian' },
  { label: 'Botswanan', value: 'Botswanan' },
  { label: 'Brazilian', value: 'Brazilian' },
  { label: 'British', value: 'British' },
  { label: 'British Virgin Islander', value: 'British Virgin Islander' },
  { label: 'Bruneian', value: 'Bruneian' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Burkinan', value: 'Burkinan' },
  { label: 'Burmese', value: 'Burmese' },
  { label: 'Burundian', value: 'Burundian' },
  { label: 'Cambodian', value: 'Cambodian' },
  { label: 'Cameroonian', value: 'Cameroonian' },
  { label: 'Canadian', value: 'Canadian' },
  { label: 'Cape Verdean', value: 'Cape Verdean' },
  { label: 'Cayman Islander', value: 'Cayman Islander' },
  { label: 'Central African', value: 'Central African' },
  { label: 'Chadian', value: 'Chadian' },
  { label: 'Chilean', value: 'Chilean' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Citizen of Antigua and Barbuda', value: 'Citizen of Antigua and Barbuda' },
  {
    label: 'Citizen of Bosnia and Herzegovina',
    value: 'Citizen of Bosnia and Herzegovina',
  },
  { label: 'Citizen of Guinea-Bissau', value: 'Citizen of Guinea-Bissau' },
  { label: 'Citizen of Kiribati', value: 'Citizen of Kiribati' },
  { label: 'Citizen of Seychelles', value: 'Citizen of Seychelles' },
  {
    label: 'Citizen of the Dominican Republic',
    value: 'Citizen of the Dominican Republic',
  },
  { label: 'Citizen of Vanuatu ', value: 'Citizen of Vanuatu ' },
  { label: 'Colombian', value: 'Colombian' },
  { label: 'Comoran', value: 'Comoran' },
  { label: 'Congolese (Congo)', value: 'Congolese (Congo)' },
  { label: 'Congolese (DRC)', value: 'Congolese (DRC)' },
  { label: 'Cook Islander', value: 'Cook Islander' },
  { label: 'Costa Rican', value: 'Costa Rican' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Cuban', value: 'Cuban' },
  { label: 'Cymraes', value: 'Cymraes' },
  { label: 'Cymro', value: 'Cymro' },
  { label: 'Cypriot', value: 'Cypriot' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Djiboutian', value: 'Djiboutian' },
  { label: 'Dominican', value: 'Dominican' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'East Timorese', value: 'East Timorese' },
  { label: 'Ecuadorean', value: 'Ecuadorean' },
  { label: 'Egyptian', value: 'Egyptian' },
  { label: 'Emirati', value: 'Emirati' },
  { label: 'English', value: 'English' },
  { label: 'Equatorial Guinean', value: 'Equatorial Guinean' },
  { label: 'Eritrean', value: 'Eritrean' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Ethiopian', value: 'Ethiopian' },
  { label: 'Faroese', value: 'Faroese' },
  { label: 'Fijian', value: 'Fijian' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'Gabonese', value: 'Gabonese' },
  { label: 'Gambian', value: 'Gambian' },
  { label: 'Georgian', value: 'Georgian' },
  { label: 'German', value: 'German' },
  { label: 'Ghanaian', value: 'Ghanaian' },
  { label: 'Gibraltarian', value: 'Gibraltarian' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Greenlandic', value: 'Greenlandic' },
  { label: 'Grenadian', value: 'Grenadian' },
  { label: 'Guamanian', value: 'Guamanian' },
  { label: 'Guatemalan', value: 'Guatemalan' },
  { label: 'Guinean', value: 'Guinean' },
  { label: 'Guyanese', value: 'Guyanese' },
  { label: 'Haitian', value: 'Haitian' },
  { label: 'Honduran', value: 'Honduran' },
  { label: 'Hong Konger', value: 'Hong Konger' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indian', value: 'Indian' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Iranian', value: 'Iranian' },
  { label: 'Iraqi', value: 'Iraqi' },
  { label: 'Irish', value: 'Irish' },
  { label: 'Israeli', value: 'Israeli' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Ivorian', value: 'Ivorian' },
  { label: 'Jamaican', value: 'Jamaican' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Jordanian', value: 'Jordanian' },
  { label: 'Kazakh', value: 'Kazakh' },
  { label: 'Kenyan', value: 'Kenyan' },
  { label: 'Kittitian', value: 'Kittitian' },
  { label: 'Kosovan', value: 'Kosovan' },
  { label: 'Kuwaiti', value: 'Kuwaiti' },
  { label: 'Kyrgyz', value: 'Kyrgyz' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lebanese', value: 'Lebanese' },
  { label: 'Liberian', value: 'Liberian' },
  { label: 'Libyan', value: 'Libyan' },
  { label: 'Liechtenstein citizen', value: 'Liechtenstein citizen' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Luxembourger', value: 'Luxembourger' },
  { label: 'Macanese', value: 'Macanese' },
  { label: 'Macedonian', value: 'Macedonian' },
  { label: 'Malagasy', value: 'Malagasy' },
  { label: 'Malawian', value: 'Malawian' },
  { label: 'Malaysian', value: 'Malaysian' },
  { label: 'Maldivian', value: 'Maldivian' },
  { label: 'Malian', value: 'Malian' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Marshallese', value: 'Marshallese' },
  { label: 'Martiniquais', value: 'Martiniquais' },
  { label: 'Mauritanian', value: 'Mauritanian' },
  { label: 'Mauritian', value: 'Mauritian' },
  { label: 'Mexican', value: 'Mexican' },
  { label: 'Micronesian', value: 'Micronesian' },
  { label: 'Moldovan', value: 'Moldovan' },
  { label: 'Monegasque', value: 'Monegasque' },
  { label: 'Mongolian', value: 'Mongolian' },
  { label: 'Montenegrin', value: 'Montenegrin' },
  { label: 'Montserratian', value: 'Montserratian' },
  { label: 'Moroccan', value: 'Moroccan' },
  { label: 'Mosotho', value: 'Mosotho' },
  { label: 'Mozambican', value: 'Mozambican' },
  { label: 'Namibian', value: 'Namibian' },
  { label: 'Nauruan', value: 'Nauruan' },
  { label: 'Nepalese', value: 'Nepalese' },
  { label: 'New Zealander', value: 'New Zealander' },
  { label: 'Nicaraguan', value: 'Nicaraguan' },
  { label: 'Nigerian', value: 'Nigerian' },
  { label: 'Nigerien', value: 'Nigerien' },
  { label: 'Niuean', value: 'Niuean' },
  { label: 'North Korean', value: 'North Korean' },
  { label: 'Northern Irish', value: 'Northern Irish' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Omani', value: 'Omani' },
  { label: 'Pakistani', value: 'Pakistani' },
  { label: 'Palauan', value: 'Palauan' },
  { label: 'Palestinian', value: 'Palestinian' },
  { label: 'Panamanian', value: 'Panamanian' },
  { label: 'Papua New Guinean', value: 'Papua New Guinean' },
  { label: 'Paraguayan', value: 'Paraguayan' },
  { label: 'Peruvian', value: 'Peruvian' },
  { label: 'Pitcairn Islander', value: 'Pitcairn Islander' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Prydeinig', value: 'Prydeinig' },
  { label: 'Puerto Rican', value: 'Puerto Rican' },
  { label: 'Qatari', value: 'Qatari' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Rwandan', value: 'Rwandan' },
  { label: 'Salvadorean', value: 'Salvadorean' },
  { label: 'Sammarinese', value: 'Sammarinese' },
  { label: 'Samoan', value: 'Samoan' },
  { label: 'Sao Tomean', value: 'Sao Tomean' },
  { label: 'Saudi Arabian', value: 'Saudi Arabian' },
  { label: 'Scottish', value: 'Scottish' },
  { label: 'Senegalese', value: 'Senegalese' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Sierra Leonean', value: 'Sierra Leonean' },
  { label: 'Singaporean', value: 'Singaporean' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Solomon Islander', value: 'Solomon Islander' },
  { label: 'Somali', value: 'Somali' },
  { label: 'South African', value: 'South African' },
  { label: 'South Korean', value: 'South Korean' },
  { label: 'South Sudanese', value: 'South Sudanese' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Sri Lankan', value: 'Sri Lankan' },
  { label: 'St Helenian', value: 'St Helenian' },
  { label: 'St Lucian', value: 'St Lucian' },
  { label: 'Stateless', value: 'Stateless' },
  { label: 'Sudanese', value: 'Sudanese' },
  { label: 'Surinamese', value: 'Surinamese' },
  { label: 'Swazi', value: 'Swazi' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Swiss', value: 'Swiss' },
  { label: 'Syrian', value: 'Syrian' },
  { label: 'Taiwanese', value: 'Taiwanese' },
  { label: 'Tajik', value: 'Tajik' },
  { label: 'Tanzanian', value: 'Tanzanian' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Togolese', value: 'Togolese' },
  { label: 'Tongan', value: 'Tongan' },
  { label: 'Trinidadian', value: 'Trinidadian' },
  { label: 'Tristanian', value: 'Tristanian' },
  { label: 'Tunisian', value: 'Tunisian' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Turkmen', value: 'Turkmen' },
  { label: 'Turks and Caicos Islander', value: 'Turks and Caicos Islander' },
  { label: 'Tuvaluan', value: 'Tuvaluan' },
  { label: 'Ugandan', value: 'Ugandan' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Uruguayan', value: 'Uruguayan' },
  { label: 'Uzbek', value: 'Uzbek' },
  { label: 'Vatican citizen', value: 'Vatican citizen' },
  { label: 'Venezuelan', value: 'Venezuelan' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Vincentian', value: 'Vincentian' },
  { label: 'Wallisian', value: 'Wallisian' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Yemeni', value: 'Yemeni' },
  { label: 'Zambian', value: 'Zambian' },
  { label: 'Zimbabwean', value: 'Zimbabwean' },
];

export const relationship = [
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Sibling', label: 'Sibling' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Grandmother', label: 'Grandmother' },
  { value: 'Grandfather', label: 'Grandfather' },
  { value: 'Uncle', label: 'Uncle' },
  { value: 'Aunt', label: 'Aunt' },
  { value: 'Cousin', label: 'Cousin' },
  { value: 'Relative', label: 'Relative' },
];

export const keyRelationship = {
  Father: 'Father',
  Mother: 'Mother',
  Sibling: 'Sibling',
  Brother: 'Brother',
  Sister: 'Sister',
  Grandmother: 'Grandmother',
  Grandfather: 'Grandfather',
  Uncle: 'Uncle',
  Aunt: 'Aunt',
  Cousin: 'Cousin',
  Relative: 'Relative',
};

export const occupation = [
  { label: 'Accountant', value: 'Accountant' },
  { label: 'Actor/ Actress', value: 'Actor/ Actress' },
  { label: 'Architect', value: 'Architect' },
  { label: 'Artist', value: 'Artist' },
  { label: 'Astronaut', value: 'Astronaut' },
  { label: 'Baker', value: 'Baker' },
  { label: 'Ballet Dancer', value: 'Ballet Dancer' },
  { label: 'Bartender', value: 'Bartender' },
  { label: 'Bellboy', value: 'Bellboy' },
  { label: 'Biologist', value: 'Biologist' },
  { label: 'Boxer', value: 'Boxer' },
  { label: 'Bus Driver', value: 'Bus Driver' },
  { label: 'Businessman', value: 'Businessman' },
  { label: 'Butcher', value: 'Butcher' },
  { label: 'Cameraman', value: 'Cameraman' },
  { label: 'Caretaker', value: 'Caretaker' },
  { label: 'Carpenter', value: 'Carpenter' },
  { label: 'Cashier', value: 'Cashier' },
  { label: 'Chef', value: 'Chef' },
  { label: 'Computer Programmer', value: 'Computer Programmer' },
  { label: 'Customs Officer', value: 'Customs Officer' },
  { label: 'Delivery Man', value: 'Delivery Man' },
  { label: 'Dentist', value: 'Dentist' },
  { label: 'Diver', value: 'Diver' },
  { label: 'Doctor', value: 'Doctor' },
  { label: 'Electrician', value: 'Electrician' },
  { label: 'Engineer', value: 'Engineer' },
  { label: 'Entrepreneur', value: 'Entrepreneur' },
  { label: 'Factory Worker', value: 'Factory Worker' },
  { label: 'Farmer', value: 'Farmer' },
  { label: 'Fireman', value: 'Fireman' },
  { label: 'Fisherman', value: 'Fisherman' },
  { label: 'Flight Attendant', value: 'Flight Attendant' },
  { label: 'Florist', value: 'Florist' },
  { label: 'Gardener', value: 'Gardener' },
  { label: 'Gas Station Attendant', value: 'Gas Station Attendant' },
  { label: 'Guitarist', value: 'Guitarist' },
  { label: 'Hairdresser', value: 'Hairdresser' },
  { label: 'Judge', value: 'Judge' },
  { label: 'Lawyer', value: 'Lawyer' },
  { label: 'Librarian', value: 'Librarian' },
  { label: 'Lifeguard', value: 'Lifeguard' },
  { label: 'Miner', value: 'Miner' },
  { label: 'Model', value: 'Model' },
  { label: 'Musician', value: 'Musician' },
  { label: 'Nurse', value: 'Nurse' },
  { label: 'Office Worker', value: 'Office Worker' },
  { label: 'Optician', value: 'Optician' },
  { label: 'Paramedic', value: 'Paramedic' },
  { label: 'Pharmacist', value: 'Pharmacist' },
  { label: 'Photographer', value: 'Photographer' },
  { label: 'Pilot', value: 'Pilot' },
  { label: 'Plumber', value: 'Plumber' },
  { label: 'Police Officer', value: 'Police Officer' },
  { label: 'Politician', value: 'Politician' },
  { label: 'Priest', value: 'Priest' },
  { label: 'Real Estate Agent', value: 'Real Estate Agent' },
  { label: 'Receptionist', value: 'Receptionist' },
  { label: 'Repairman', value: 'Repairman' },
  { label: 'Scientist', value: 'Scientist' },
  { label: 'Singer', value: 'Singer' },
  { label: 'Speaker', value: 'Speaker' },
  { label: 'Student', value: 'Student' },
  { label: 'Surgeon', value: 'Surgeon' },
  { label: 'Tailor', value: 'Tailor' },
  { label: 'Taxi Driver', value: 'Taxi Driver' },
  { label: 'Teacher', value: 'Teacher' },
  { label: 'Veterinarian', value: 'Veterinarian' },
  { label: 'Violinist', value: 'Violinist' },
  { label: 'Waiter', value: 'Waiter' },
  { label: 'Others', value: 'Others' },
];

export const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const yesNo = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const idType = [
  { value: 'Passport', label: 'Passport' },
  { value: 'Overseas Citizenship', label: 'Overseas Citizenship' },
  { value: 'PAN Card', label: 'PAN Card' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'Ration Card', label: 'Ration Card' },
  { value: 'Identity Certificate', label: 'Identity Certificate' },
  { value: 'Birth Certificate', label: 'Birth Certificate' },
];
export const INDEX_CURRENCY_INDIA = 20;
export const currencies = [
  { label: 'AFN', value: 'AFN' },
  { label: 'EUR', value: 'EUR' },
  { label: 'ALL', value: 'ALL' },
  { label: 'DZD', value: 'DZD' },
  { label: 'USD', value: 'USD' },
  { label: 'AOA', value: 'AOA' },
  { label: 'XCD', value: 'XCD' },
  { label: 'ARS', value: 'ARS' },
  { label: 'AMD', value: 'AMD' },
  { label: 'AWG', value: 'AWG' },
  { label: 'AUD', value: 'AUD' },
  { label: 'AZN', value: 'AZN' },
  { label: 'BSD', value: 'BSD' },
  { label: 'BHD', value: 'BHD' },
  { label: 'BDT', value: 'BDT' },
  { label: 'BBD', value: 'BBD' },
  { label: 'BYN', value: 'BYN' },
  { label: 'BZD', value: 'BZD' },
  { label: 'XOF', value: 'XOF' },
  { label: 'BMD', value: 'BMD' },
  { label: 'INR', value: 'INR' },
  { label: 'BTN', value: 'BTN' },
  { label: 'BOB', value: 'BOB' },
  { label: 'BOV', value: 'BOV' },
  { label: 'BAM', value: 'BAM' },
  { label: 'BWP', value: 'BWP' },
  { label: 'NOK', value: 'NOK' },
  { label: 'BRL', value: 'BRL' },
  { label: 'BND', value: 'BND' },
  { label: 'BGN', value: 'BGN' },
  { label: 'BIF', value: 'BIF' },
  { label: 'CVE', value: 'CVE' },
  { label: 'KHR', value: 'KHR' },
  { label: 'XAF', value: 'XAF' },
  { label: 'CAD', value: 'CAD' },
  { label: 'KYD', value: 'KYD' },
  { label: 'CLP', value: 'CLP' },
  { label: 'CLF', value: 'CLF' },
  { label: 'CNY', value: 'CNY' },
  { label: 'COP', value: 'COP' },
  { label: 'COU', value: 'COU' },
  { label: 'KMF', value: 'KMF' },
  { label: 'CDF', value: 'CDF' },
  { label: 'NZD', value: 'NZD' },
  { label: 'CRC', value: 'CRC' },
  { label: 'HRK', value: 'HRK' },
  { label: 'CUP', value: 'CUP' },
  { label: 'CUC', value: 'CUC' },
  { label: 'ANG', value: 'ANG' },
  { label: 'CZK', value: 'CZK' },
  { label: 'DKK', value: 'DKK' },
  { label: 'DJF', value: 'DJF' },
  { label: 'DOP', value: 'DOP' },
  { label: 'EGP', value: 'EGP' },
  { label: 'SVC', value: 'SVC' },
  { label: 'ERN', value: 'ERN' },
  { label: 'SZL', value: 'SZL' },
  { label: 'ETB', value: 'ETB' },
  { label: 'FKP', value: 'FKP' },
  { label: 'FJD', value: 'FJD' },
  { label: 'XPF', value: 'XPF' },
  { label: 'GMD', value: 'GMD' },
  { label: 'GEL', value: 'GEL' },
  { label: 'GHS', value: 'GHS' },
  { label: 'GIP', value: 'GIP' },
  { label: 'GTQ', value: 'GTQ' },
  { label: 'GBP', value: 'GBP' },
  { label: 'GNF', value: 'GNF' },
  { label: 'GYD', value: 'GYD' },
  { label: 'HTG', value: 'HTG' },
  { label: 'HNL', value: 'HNL' },
  { label: 'HKD', value: 'HKD' },
  { label: 'HUF', value: 'HUF' },
  { label: 'ISK', value: 'ISK' },
  { label: 'IDR', value: 'IDR' },
  { label: 'XDR', value: 'XDR' },
  { label: 'IRR', value: 'IRR' },
  { label: 'IQD', value: 'IQD' },
  { label: 'ILS', value: 'ILS' },
  { label: 'JMD', value: 'JMD' },
  { label: 'JPY', value: 'JPY' },
  { label: 'JOD', value: 'JOD' },
  { label: 'KZT', value: 'KZT' },
  { label: 'KES', value: 'KES' },
  { label: 'KPW', value: 'KPW' },
  { label: 'KRW', value: 'KRW' },
  { label: 'KWD', value: 'KWD' },
  { label: 'KGS', value: 'KGS' },
  { label: 'LAK', value: 'LAK' },
  { label: 'LBP', value: 'LBP' },
  { label: 'LSL', value: 'LSL' },
  { label: 'ZAR', value: 'ZAR' },
  { label: 'LRD', value: 'LRD' },
  { label: 'LYD', value: 'LYD' },
  { label: 'CHF', value: 'CHF' },
  { label: 'MOP', value: 'MOP' },
  { label: 'MKD', value: 'MKD' },
  { label: 'MGA', value: 'MGA' },
  { label: 'MWK', value: 'MWK' },
  { label: 'MYR', value: 'MYR' },
  { label: 'MVR', value: 'MVR' },
  { label: 'MRU', value: 'MRU' },
  { label: 'MUR', value: 'MUR' },
  { label: 'XUA', value: 'XUA' },
  { label: 'MXN', value: 'MXN' },
  { label: 'MXV', value: 'MXV' },
  { label: 'MDL', value: 'MDL' },
  { label: 'MNT', value: 'MNT' },
  { label: 'MAD', value: 'MAD' },
  { label: 'MZN', value: 'MZN' },
  { label: 'MMK', value: 'MMK' },
  { label: 'NAD', value: 'NAD' },
  { label: 'NPR', value: 'NPR' },
  { label: 'NIO', value: 'NIO' },
  { label: 'NGN', value: 'NGN' },
  { label: 'OMR', value: 'OMR' },
  { label: 'PKR', value: 'PKR' },
  { label: 'PAB', value: 'PAB' },
  { label: 'PGK', value: 'PGK' },
  { label: 'PYG', value: 'PYG' },
  { label: 'PEN', value: 'PEN' },
  { label: 'PHP', value: 'PHP' },
  { label: 'PLN', value: 'PLN' },
  { label: 'QAR', value: 'QAR' },
  { label: 'RON', value: 'RON' },
  { label: 'RUB', value: 'RUB' },
  { label: 'RWF', value: 'RWF' },
  { label: 'SHP', value: 'SHP' },
  { label: 'WST', value: 'WST' },
  { label: 'STN', value: 'STN' },
  { label: 'SAR', value: 'SAR' },
  { label: 'RSD', value: 'RSD' },
  { label: 'SCR', value: 'SCR' },
  { label: 'SLL', value: 'SLL' },
  { label: 'SGD', value: 'SGD' },
  { label: 'XSU', value: 'XSU' },
  { label: 'SBD', value: 'SBD' },
  { label: 'SOS', value: 'SOS' },
  { label: 'SSP', value: 'SSP' },
  { label: 'LKR', value: 'LKR' },
  { label: 'SDG', value: 'SDG' },
  { label: 'SRD', value: 'SRD' },
  { label: 'SEK', value: 'SEK' },
  { label: 'CHE', value: 'CHE' },
  { label: 'CHW', value: 'CHW' },
  { label: 'SYP', value: 'SYP' },
  { label: 'TWD', value: 'TWD' },
  { label: 'TJS', value: 'TJS' },
  { label: 'TZS', value: 'TZS' },
  { label: 'THB', value: 'THB' },
  { label: 'TOP', value: 'TOP' },
  { label: 'TTD', value: 'TTD' },
  { label: 'TND', value: 'TND' },
  { label: 'TRY', value: 'TRY' },
  { label: 'TMT', value: 'TMT' },
  { label: 'UGX', value: 'UGX' },
  { label: 'UAH', value: 'UAH' },
  { label: 'AED', value: 'AED' },
  { label: 'USN', value: 'USN' },
  { label: 'UYU', value: 'UYU' },
  { label: 'UYI', value: 'UYI' },
  { label: 'UYW', value: 'UYW' },
  { label: 'UZS', value: 'UZS' },
  { label: 'VUV', value: 'VUV' },
  { label: 'VES', value: 'VES' },
  { label: 'VND', value: 'VND' },
  { label: 'YER', value: 'YER' },
  { label: 'ZMW', value: 'ZMW' },
  { label: 'ZWL', value: 'ZWL' },
  { label: 'XBA', value: 'XBA' },
  { label: 'XBB', value: 'XBB' },
  { label: 'XBC', value: 'XBC' },
  { label: 'XBD', value: 'XBD' },
  { label: 'XTS', value: 'XTS' },
  { label: 'XXX', value: 'XXX' },
  { label: 'XAU', value: 'XAU' },
  { label: 'XPD', value: 'XPD' },
  { label: 'XPT', value: 'XPT' },
  { label: 'XAG', value: 'XAG' },
  { label: 'AFA', value: 'AFA' },
  { label: 'FIM', value: 'FIM' },
  { label: 'ALK', value: 'ALK' },
  { label: 'ADP', value: 'ADP' },
  { label: 'ESP', value: 'ESP' },
  { label: 'FRF', value: 'FRF' },
  { label: 'AOK', value: 'AOK' },
  { label: 'AON', value: 'AON' },
  { label: 'AOR', value: 'AOR' },
  { label: 'ARA', value: 'ARA' },
  { label: 'ARP', value: 'ARP' },
  { label: 'ARY', value: 'ARY' },
  { label: 'RUR', value: 'RUR' },
  { label: 'ATS', value: 'ATS' },
  { label: 'AYM', value: 'AYM' },
  { label: 'AZM', value: 'AZM' },
  { label: 'BYB', value: 'BYB' },
  { label: 'BYR', value: 'BYR' },
  { label: 'BEC', value: 'BEC' },
  { label: 'BEF', value: 'BEF' },
  { label: 'BEL', value: 'BEL' },
  { label: 'BOP', value: 'BOP' },
  { label: 'BAD', value: 'BAD' },
  { label: 'BRB', value: 'BRB' },
  { label: 'BRC', value: 'BRC' },
  { label: 'BRE', value: 'BRE' },
  { label: 'BRN', value: 'BRN' },
  { label: 'BRR', value: 'BRR' },
  { label: 'BGJ', value: 'BGJ' },
  { label: 'BGK', value: 'BGK' },
  { label: 'BGL', value: 'BGL' },
  { label: 'BUK', value: 'BUK' },
  { label: 'HRD', value: 'HRD' },
  { label: 'CYP', value: 'CYP' },
  { label: 'CSJ', value: 'CSJ' },
  { label: 'CSK', value: 'CSK' },
  { label: 'ECS', value: 'ECS' },
  { label: 'ECV', value: 'ECV' },
  { label: 'GQE', value: 'GQE' },
  { label: 'EEK', value: 'EEK' },
  { label: 'XEU', value: 'XEU' },
  { label: 'GEK', value: 'GEK' },
  { label: 'DDM', value: 'DDM' },
  { label: 'DEM', value: 'DEM' },
  { label: 'GHC', value: 'GHC' },
  { label: 'GHP', value: 'GHP' },
  { label: 'GRD', value: 'GRD' },
  { label: 'GNE', value: 'GNE' },
  { label: 'GNS', value: 'GNS' },
  { label: 'GWE', value: 'GWE' },
  { label: 'GWP', value: 'GWP' },
  { label: 'ITL', value: 'ITL' },
  { label: 'ISJ', value: 'ISJ' },
  { label: 'IEP', value: 'IEP' },
  { label: 'ILP', value: 'ILP' },
  { label: 'ILR', value: 'ILR' },
  { label: 'LAJ', value: 'LAJ' },
  { label: 'LVL', value: 'LVL' },
  { label: 'LVR', value: 'LVR' },
  { label: 'LSM', value: 'LSM' },
  { label: 'ZAL', value: 'ZAL' },
  { label: 'LTL', value: 'LTL' },
  { label: 'LTT', value: 'LTT' },
  { label: 'LUC', value: 'LUC' },
  { label: 'LUF', value: 'LUF' },
  { label: 'LUL', value: 'LUL' },
  { label: 'MGF', value: 'MGF' },
  { label: 'MVQ', value: 'MVQ' },
  { label: 'MLF', value: 'MLF' },
  { label: 'MTL', value: 'MTL' },
  { label: 'MTP', value: 'MTP' },
  { label: 'MRO', value: 'MRO' },
  { label: 'MXP', value: 'MXP' },
  { label: 'MZE', value: 'MZE' },
  { label: 'MZM', value: 'MZM' },
  { label: 'NLG', value: 'NLG' },
  { label: 'NIC', value: 'NIC' },
  { label: 'PEH', value: 'PEH' },
  { label: 'PEI', value: 'PEI' },
  { label: 'PES', value: 'PES' },
  { label: 'PLZ', value: 'PLZ' },
  { label: 'PTE', value: 'PTE' },
  { label: 'ROK', value: 'ROK' },
  { label: 'ROL', value: 'ROL' },
  { label: 'STD', value: 'STD' },
  { label: 'CSD', value: 'CSD' },
  { label: 'SKK', value: 'SKK' },
  { label: 'SIT', value: 'SIT' },
  { label: 'RHD', value: 'RHD' },
  { label: 'ESA', value: 'ESA' },
  { label: 'ESB', value: 'ESB' },
  { label: 'SDD', value: 'SDD' },
  { label: 'SDP', value: 'SDP' },
  { label: 'SRG', value: 'SRG' },
  { label: 'CHC', value: 'CHC' },
  { label: 'TJR', value: 'TJR' },
  { label: 'TPE', value: 'TPE' },
  { label: 'TRL', value: 'TRL' },
  { label: 'TMM', value: 'TMM' },
  { label: 'UGS', value: 'UGS' },
  { label: 'UGW', value: 'UGW' },
  { label: 'UAK', value: 'UAK' },
  { label: 'SUR', value: 'SUR' },
  { label: 'USS', value: 'USS' },
  { label: 'UYN', value: 'UYN' },
  { label: 'UYP', value: 'UYP' },
  { label: 'VEB', value: 'VEB' },
  { label: 'VEF', value: 'VEF' },
  { label: 'VNC', value: 'VNC' },
  { label: 'YDD', value: 'YDD' },
  { label: 'YUD', value: 'YUD' },
  { label: 'YUM', value: 'YUM' },
  { label: 'YUN', value: 'YUN' },
  { label: 'ZRN', value: 'ZRN' },
  { label: 'ZRZ', value: 'ZRZ' },
  { label: 'ZMK', value: 'ZMK' },
  { label: 'ZWC', value: 'ZWC' },
  { label: 'ZWD', value: 'ZWD' },
  { label: 'ZWN', value: 'ZWN' },
  { label: 'ZWR', value: 'ZWR' },
  { label: 'XFO', value: 'XFO' },
  { label: 'XRE', value: 'XRE' },
  { label: 'XFU', value: 'XFU' },
];

export const camelToCapitalizeEachWord = (str) => {
  const tempString = str.replace(/[A-Z]/g, (letter) => ` ${letter}`);
  const result = tempString.charAt(0).toUpperCase() + tempString.slice(1);
  return result;
};

export const removeDuplicateObjectsInArray = (arr) => {
  const uniqueArray = arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return uniqueArray;
};

export const transformStringToLowercase = (text) => {
  if (!text) return undefined;
  const regex = /\s+/gi;
  return text.toLowerCase().replace(regex, '-');
};

export const calcPercent = (oldPercent, oldValue, newValue) => {
  const prevFee = oldValue / (1 + oldPercent / 100);
  return ((100 * (newValue - prevFee)) / prevFee).toFixed(2);
};
