import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { listTime } from 'constant';
import { ToastMessageContext } from 'context/toast-context';
import { mainavatar, search } from 'static/image';
import { selectGroup } from 'entities/select-style';
import { checkErrorApiFetch, checkErrorSingleApi } from 'utils/check-error/api-error';
import Chip from '../chip/chip';
import SelectGroup from '../selectgroup/selectgroup';
import './scheduled.scss';
import authApi from 'api/auth';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const USER_ROLE = {
  TEACHER: 'Teacher',
};

// const colorOption = ['#404EED', '#663174', '#F2C94C', '#00AB55', '#FFA48D'];

export default function Scheduled(props) {
  const {
    isCompletedOrClosed,
    exam,
    applicationId,
    setExam,
    setSchedule,
    isHaveResult,
    campusId,
  } = props;
  const [bloom, setBloom] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState({
    value: exam?.proctor?.id,
    label: exam?.proctor?.name,
    info: exam?.proctor,
  });
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const user = useStorage((state) => state.auth);
  const avatar = user?.photoURL || mainavatar;

  useEffect(() => {
    const user = exam?.proctor?.user_position?.[0]?.users;
    if (user) {
      setSelectedTeacher({
        value: user.id,
        label: user.name,
        info: user,
      });
    }
  }, [exam]);

  useEffect(() => {
    // authApi.listUserOfCompanyOrCampus({ filterRoleNames: USER_ROLE.TEACHER, campusId, isGetAll: true })
    authApi
      .getUserOfCompanyOrCampus({
        filterRoleNames: USER_ROLE.TEACHER,
        campusId,
        isGetAll: true,
      })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get User Of Company Or Campus',
          )
        ) {
          const data = (res.data?.data?.objects || []).map((user, key) => {
            return {
              value: user.id,
              label: user.name,
              info: user,
            };
          });
          data?.length && setListUsers(data);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get User Of Company Or Campus Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Schedule Error', error);
      });
  }, [campusId, setIsShowToastMessage, setToastMessage]);

  // useEffect(() => {
  //   if (exam?.proctorUserId) {
  //     const res = callApi({
  //       method: 'get',
  //       url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/user/${exam?.proctorUserId}`,
  //     });
  //     res
  //       .then((res) => {
  //         const { data } = res;
  //         if (!data.success) {
  //           setToastMessage({
  //             status: 'error',
  //             title: 'Get User Of Company Or Campus Failed',
  //             message: res.data.message,
  //           });
  //           setIsShowToastMessage(true);
  //         } else {
  //           setPresentProctor(data.data);
  //           setSelectProctor(data.data);
  //         }
  //       })
  //       .catch((error) => {
  //         setToastMessage({
  //           status: 'error',
  //           title: 'Get User Of Company Or Campus Failed',
  //           message: error.response?.data?.message || error,
  //         });
  //         setIsShowToastMessage(true);
  //         console.log('Schedule Error', error);
  //       });
  //   }
  // }, [exam]);

  const handleDate = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toUTCString();
    return data.slice(5, 16);
  };
  const handleTime = () => {
    const data = listTime.find((data) => {
      return data.value[0] === exam?.startTimeSlot && data.value[1] === exam?.endTimeSlot;
    });
    return data && data.label;
  };

  // const handleSearch = (e, searchData) => {
  //   e.preventDefault();
  //   const searchResults = listUsers.filter((user) => {
  //     const searchName = user.name.toLowerCase().includes(searchData.toLowerCase());
  //     const searchEmail = user.email.toLowerCase().includes(searchData.toLowerCase());
  //     return searchName || searchEmail;
  //   });
  //   setSearchUserResult(searchResults);
  //   setIsSearch(true);
  // };

  const handleAssignProctor = (e) => {
    e.preventDefault();
    if (selectedTeacher.length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Assign Proctor',
        message: 'Please select at least one teacher!',
      });
      setIsShowToastMessage(true);
      return;
    }

    const data = {
      proctorUserId: selectedTeacher.value,
      applicationId: applicationId,
      testDate: exam?.testDate,
      startTimeSlot: exam?.startTimeSlot,
      endTimeSlot: exam?.endTimeSlot,
    };

    admissionApi
      .updateApplicationExam({ campusId: user.campusId }, data, exam?.id)
      .then((res) => {
        const { data } = res;
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Assign Proctor',
          )
        ) {
          setToastMessage({
            status: 'success',
            title: 'Assign Proctor Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setExam(data.data);
          // setIsSearch(false);
          // setSearchData('');
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Assign Proctor Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Assign Proctor Error', error);
      });
  };

  // function titleCase(str) {
  //   return str.toLowerCase().replace(/(^|\s)\S/g, function (l) {
  //     return l.toUpperCase();
  //   });
  // }

  const formatOptionLabelUser = ({ value, label, info }) => (
    <Chip
      key={info?.id}
      label={label}
      image={info?.photoURL || mainavatar}
      chipAvatarStyle={{ width: 18, height: 18 }}
      styleChip={'object-cover'}
    />
  );

  return (
    <div className={clsx('your-exam mb-10', bloom && 'shadow-none')}>
      <div className="date">
        {!bloom && (
          <div className="date_scheduler">
            <p className="date_scheduler-text">Test Date is Scheduled by</p>
            <img alt="" src={avatar} className="date_scheduler-image" />
          </div>
        )}
        <div className="date_details">
          <div className="date_details-date">
            <p className="date_details-text">Test Date</p>
            <p className="date_details-schedule">{handleDate(exam?.testDate)}</p>
          </div>
          <div className="date_details-time">
            <p className="date_details-text">Time</p>
            <p className="date_details-schedule">{handleTime()}</p>
          </div>
        </div>
        {!isHaveResult && (
          <button
            onClick={() => {
              setSchedule(false);
            }}
            className="date_button"
          >
            Reschedule Test
          </button>
        )}
      </div>
      {!bloom ? (
        // <div className="proctor">
        //   <p className="proctor_text">Assign a proctor</p>
        //   {presentProctor && (
        //     <div
        //       style={{
        //         width: '95%',
        //       }}
        //       className="flex items-center mb-2 ml-2"
        //     >
        //       <img
        //         src={presentProctor?.photoURL || mainavatar}
        //         alt="proctor"
        //         className="w-8 h-8 rounded-full mr-3"
        //       />
        //       <p className="text-sm font-medium">
        //         {presentProctor?.name && titleCase(presentProctor?.name)}
        //       </p>
        //     </div>
        //   )}
        //   <div
        //     onBlur={() => {
        //       if (!searchData) {
        //         setIsSearch(false);
        //       }
        //     }}
        //   >
        //     <div className="proctor_search mb-4">
        //       <img
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           if (!isHaveResult) {
        //             handleSearch(e, searchData);
        //           }
        //         }}
        //         src={search}
        //         alt=""
        //         className="proctor_search-tool cursor-pointer"
        //       />
        //       <input
        //         type="text"
        //         placeholder="Search Teacher"
        //         className="proctor_search-input focus:outline-none focus:border-none"
        //         value={searchData}
        //         disabled={isHaveResult}
        //         onChange={(e) => {
        //           setSearchData(e.target.value);
        //         }}
        //         onKeyDown={(e) => {
        //           if (e.key === 'Enter') {
        //             handleSearch(e, searchData);
        //           }
        //         }}
        //       />
        //     </div>
        //     <div className="h-32 overflow-y-auto">
        //       {isSearch && searchUserResult.length === 0 && <div>Have no user</div>}
        //       {isSearch && searchUserResult.length !== 0 && (
        //         <div>
        //           {searchUserResult.map((user, key) => {
        //             return (
        //               <div
        //                 key={key}
        //                 style={{
        //                   width: '95%',
        //                   boxShadow:
        //                     '0px 0px 1px rgba(145, 158, 171, 0.24), 0px 3px 3px rgba(145, 158, 171, 0.24)',
        //                   border:
        //                     selectProctor?.name === user.name ? '1px solid blue' : '',
        //                 }}
        //                 className="flex items-center mb-2 rounded border hover:border-indigo-500/100 cursor-pointer"
        //                 onClick={(e) => {
        //                   e.stopPropagation();
        //                   setSelectProctor(user);
        //                 }}
        //               >
        //                 <img
        //                   src={user?.photoURL || mainavatar}
        //                   alt="proctor"
        //                   className="w-8 h-8 rounded-full mr-3"
        //                 />
        //                 <p className="text-sm font-medium">{titleCase(user.name)}</p>
        //               </div>
        //             );
        //           })}
        //         </div>
        //       )}
        //     </div>
        //   </div>
        //   <div className="h-4"></div>
        //   {!isHaveResult && (
        //     <div className="flex">
        //       <button
        //         onClick={(e) => {
        //           handleAssignProctor(e);
        //         }}
        //         className="proctor_button"
        //       >
        //         Assign Proctor
        //       </button>
        //     </div>
        //   )}
        // </div>
        <div className="proctor">
          <p className="proctor_text">Assign a proctor</p>

          <div className="flex items-center mb-2 ml-2">
            <img
              onClick={(e) => {
                e.stopPropagation();
              }}
              src={search}
              alt=""
              className="ml-2"
            />
            <div className="w-full">
              <SelectGroup
                className="text-sm"
                placeholder="Search Teacher"
                value={selectedTeacher.value ? selectedTeacher : null}
                styles={selectGroup}
                options={listUsers}
                formatOptionLabel={formatOptionLabelUser}
                isMulti={false}
                isSearchable={true}
                onChange={setSelectedTeacher}
              />
            </div>
          </div>
          <div className="mt-[9.3rem]">
            <button
              disabled={isCompletedOrClosed}
              onClick={(e) => {
                handleAssignProctor(e);
              }}
              className="proctor_button"
            >
              Assign Proctor
            </button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-start">
          <p className="relative font-semibold" style={{ color: '#ababcd' }}>
            wanting to re-schedule?{' '}
            <span
              onClick={(e) => {
                e.preventDefault();
                setBloom(false);
              }}
              className="text-main-blue cursor-pointer"
            >
              click here
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
