import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { urls } from 'entities/urls';

const FinanceDashboard = lazy(() => import('@finance/pages/dashboard/FinanceDashboard'));

const AcademicTerms = lazy(() =>
  import('./pages').then((m) => ({ default: m.AcademicTerms })),
);
const GradesPrograms = lazy(() =>
  import('./pages').then((m) => ({ default: m.GradesPrograms })),
);
const ProgramClasses = lazy(() =>
  import('./pages').then((m) => ({ default: m.ProgramClasses })),
);
const GradesFeePayment = lazy(() =>
  import('./pages').then((m) => ({ default: m.GradesFeePayment })),
);
const Programmes = lazy(() => import('./pages').then((m) => ({ default: m.Programmes })));
const Scholarship = lazy(() =>
  import('./pages').then((m) => ({ default: m.Scholarship })),
);

export const AcademicRoutes = ({ burger }) => (
  <>
    <Route
      exact
      path={urls.campus.setting.academicYears}
      element={<AcademicTerms burger={burger} />}
    />
    <Route exact path="/campus/grades" element={<GradesPrograms burger={burger} />} />
    <Route
      exact
      path={urls.campus.setting.programClasses}
      element={<ProgramClasses burger={burger} />}
    />
    <Route
      exact
      path="/central-admin/campus/fee-payment"
      element={<GradesFeePayment burger={burger} />}
    />
    <Route
      exact
      path="/central-admin/campus/programmes/:id"
      element={<Programmes burger={burger} />}
    />
    <Route
      exact
      path={urls.campus.finance.scholarships}
      element={<Scholarship burger={burger} />}
    />
    <Route
      exact
      path="/central-admin/dashboard"
      element={<FinanceDashboard burger={burger} />}
    />
  </>
);
