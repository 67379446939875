import { ToastMessageContext } from 'context/toast-context';
import { useContext, useCallback } from 'react';

export function useNotifications() {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  return useCallback(
    function (status, title, message = '') {
      setToastMessage({
        status,
        title,
        message,
      });
      setIsShowToastMessage(true);
    },
    [setToastMessage, setIsShowToastMessage],
  );
}
