import { Route } from 'react-router-dom';
import { urls } from 'entities/urls';

import WhatsappCredentials from './pages/whatsapp-credentials';
import NotificationsPage from './pages/notifications';
import Communication from './pages/communication';
import EditTemplate from './pages/edit-template';

export const CommunicationsRoutes = ({ burger }) => (
  <>
    <Route
      exact
      path="/central-admin/settings/whatsapp-credentials"
      element={<WhatsappCredentials burger={burger} />}
    />
    <Route
      exact
      path="/central/integration/whatsapps"
      element={<WhatsappCredentials burger={burger} />}
    />
    <Route
      exact
      path={urls.central.communicationNotification.notifications}
      element={<NotificationsPage burger={burger} />}
    />
    <Route
      exact
      path={urls.central.communicationNotification.communicationTemplates}
      element={<Communication burger={burger} />}
    />
    <Route
      exact
      path={urls.central.communicationNotification.templateEdit}
      element={<EditTemplate burger={burger} />}
    />
  </>
);
