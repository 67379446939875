import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import './navbar.scss';
import { icchevronright, logoLogin, mainavatar } from 'static/image';
import {
  BASE_CENTRAL_APPROVAL_URL,
  BASE_CENTRAL_FINANCE_URL,
  BASE_CENTRAL_SETUP_URL,
  BASE_GIIKI_URL,
} from 'entities/urls';
import useStorage from 'store/storage';
import UserCardSwitch from '../UserCardSwitch';
import PopupSwitchAcademicYear from '../PopupSwitchAcademicYear';
import PopupSwitchCampus from '../PopupSwitchCampus';

const NewBigPanel = (props) => {
  const { burger, isOpen } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const [multiActive, setMultiActive] = useState({});
  const auth = useStorage((state) => state.auth);
  const title = useStorage((state) => state.navbarTitle);
  const dataRoutes = useStorage((state) => state.routes);
  const [openList, setOpenList] = useState({ campus: false, year: false });

  const onClickMulti = (name) => {
    setMultiActive((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const MultiPath = (props) => {
    const { data } = props;
    return (
      <React.Fragment key={data.path}>
        <button
          className={`flex items-center h-[2.188rem] mb-[0.5rem] w-[96%] rounded-[0.75rem] ${
            multiActive[data.name]
              ? 'basic-panel-navbar_link-active'
              : 'basic-panel-navbar_link'
          }`}
          onClick={() => onClickMulti(data.name)}
        >
          <img src={data?.icon} alt="" />
          {data.name}
          <img
            className={clsx('mr-0', multiActive[data.name] ? 'rotate_90' : 'rotate_0')}
            src={icchevronright}
            alt=""
          />
        </button>

        {multiActive[data.name] && (
          <div className="basic-panel-navbar_actives">
            {data.multiPath.map((route) => {
              return (
                <NavLink
                  key={route.path}
                  className={({ isActive }) =>
                    clsx(
                      'basic-panel-navbar_link_small',
                      isActive && 'basic-panel-navbar_link-active',
                    )
                  }
                  onClick={isOpen}
                  to={route.path}
                >
                  {route.name}
                </NavLink>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  };

  const mapType = {
    span: (data) => (
      <span key={data.path} className="basic-panel-navbar_name">
        {data.name}
      </span>
    ),
    multi: (data) => <MultiPath key={data.name} data={data} />,
    link: (data) => (
      <NavLink
        className={({ isActive }) =>
          clsx('basic-panel-navbar_link', isActive && 'basic-panel-navbar_link-active')
        }
        onClick={isOpen}
        to={data.path}
        key={data.path}
      >
        <img src={data.icon} alt="" />
        {data.name}
      </NavLink>
    ),
  };

  const onCloseCampusPopup = () => {
    setOpenList((prev) => ({ ...prev, campus: false }));
  };

  const onCloseYearPopup = () => {
    setOpenList((prev) => ({ ...prev, year: false }));
  };
  const isNotShowUserCard =
    pathname.startsWith(BASE_GIIKI_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_SETUP_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_FINANCE_URL + '/') ||
    pathname.startsWith(BASE_CENTRAL_APPROVAL_URL + '/');
  const isGiikiAdmin = pathname.includes(BASE_GIIKI_URL + '/');

  return (
    <div id="big-panel">
      <div
        style={{ transition: 'all 0.4s' }}
        className={clsx('basic-panel-navbar-giiki-admin ', burger ? 'open' : 'close')}
      >
        <div className="basic-panel-navbar-logo w-full">
          <NavLink to="/home" className="flex flex-col items-center">
            <img
              src={logoLogin}
              alt="logo"
              className="w-[4.5rem] m-[1.125rem] h-[2.6875rem]"
            />
            <p
              style={{
                textTransform: 'uppercase',
                color: '#000',
                fontSize: '1rem',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '1.5rem',
                height: '3rem',
                transition: 'all 0.4s',
                opacity: burger ? '1' : '0',
                width: burger ? 'fit-content' : '0px',
              }}
            >
              {title} console
            </p>
          </NavLink>
        </div>

        <div className="basic-panel-menu_profile basic-panel-menu mt-4 pl-2 overflow-x-hidden w-[94%]">
          <img
            className="basic-panel-menu_avatar"
            src={auth?.auth?.currentUser?.photoURL || mainavatar}
            alt=""
          />
          <div className="basic-panel-menu_text">
            <div className="basic-panel-menu_text-start">
              <p>{auth?.auth?.currentUser?.displayName || ''}</p>
            </div>
            <p className="basic-panel-menu_text-end">{auth?.roles?.[0]?.name || ''}</p>
          </div>
        </div>

        <div
          className="basic-panel-navbar-menu-admin overflow-x-hidden mt-2"
          style={{
            height: isGiikiAdmin ? '100%' : 'calc(100vh - 20rem)',
            position: 'initial',
          }}
        >
          {dataRoutes?.map((route) => {
            return mapType[route.type](route);
          })}
        </div>
        {!isNotShowUserCard && (
          <>
            <UserCardSwitch openList={openList} setOpenList={setOpenList} />
            <PopupSwitchCampus open={openList.campus} onClose={onCloseCampusPopup} />
            <PopupSwitchAcademicYear open={openList.year} onClose={onCloseYearPopup} />
          </>
        )}
      </div>
    </div>
  );
};

export default NewBigPanel;
