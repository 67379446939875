import clsx from 'clsx';
import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import SettingsProvider from './components/AppSettings';
import './pages/home.css';

const Home = lazy(() => import('./pages').then((m) => ({ default: m.Home })));
const Dashboard = lazy(() => import('./pages').then((m) => ({ default: m.Dashboard })));
const Locations = lazy(() => import('./pages').then((m) => ({ default: m.Locations })));
const Checklists = lazy(() => import('./pages').then((m) => ({ default: m.Checklists })));
const AChecklist = lazy(() => import('./pages').then((m) => ({ default: m.AChecklist })));
const Shifts = lazy(() => import('./pages').then((m) => ({ default: m.Shifts })));
const Calendar = lazy(() => import('./pages').then((m) => ({ default: m.Calendar })));
const Reports = lazy(() => import('./pages').then((m) => ({ default: m.Reports })));
const CampusDashboard = lazy(() =>
  import('./pages').then((m) => ({ default: m.CampusDashboard })),
);
const Requests = lazy(() => import('./pages').then((m) => ({ default: m.Requests })));
const MyTasks = lazy(() => import('./pages').then((m) => ({ default: m.MyTasks })));
const DailySchedule = lazy(() =>
  import('./pages').then((m) => ({ default: m.DailySchedule })),
);
const ServiceStatus = lazy(() =>
  import('./pages').then((m) => ({ default: m.ServiceStatus })),
);
const Settings = lazy(() => import('./pages').then((m) => ({ default: m.Settings })));

export function FieldAppRoutes({ burger }) {
  return (
    <Route path="/fieldApp" element={<Layout burger={burger} />}>
      <Route index element={<Home />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="locations" element={<Locations />} />
      <Route path="checklists" element={<Checklists />} />
      <Route path="checklists/:id" element={<AChecklist />} />
      <Route path="shifts" element={<Shifts />} />
      <Route path="calendar" element={<Calendar />} />
      <Route path="reports" element={<Reports />} />
      <Route path="campus-dashboard" element={<CampusDashboard />} />
      <Route path="requests" element={<Requests />} />
      <Route path="my-tasks" element={<MyTasks />} />
      <Route path="daily-schedule" element={<DailySchedule />} />
      <Route path="services/:id" element={<ServiceStatus />} />
      <Route path="settings" element={<Settings />} />
    </Route>
  );
}

function Layout({ burger }) {
  return (
    <SettingsProvider>
      <div
        className={clsx(
          'fieldApp pr-20 mt-20 pl-0 transition-all-300',
          burger ? '1400px:pl-72' : '1400px:pl-20',
        )}
      >
        <Outlet />
      </div>
    </SettingsProvider>
  );
}
