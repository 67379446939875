import { under_construction } from 'static/image';

const UnderConstruction = () => {
  return (
    <div className="campus-detail px-8 w-full h-[87.5vh] flex items-center justify-between">
      <img
        className="aspect-square w-[15rem] h-full"
        src={under_construction}
        alt="under-construction"
      />
    </div>
  );
};

export default UnderConstruction;
