import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { urls } from 'entities/urls';

const TasksSettings = lazy(() =>
  import('./pages').then((m) => ({ default: m.TasksSettings })),
);
const Approvals = lazy(() => import('./pages').then((m) => ({ default: m.Approvals })));
const ApprovalProcess = lazy(() =>
  import('./pages').then((m) => ({ default: m.ApprovalProcess })),
);
// const MyRequests = lazy(() =>
//   import('./pages').then((m) => ({ default: m.MyRequests })),
// );
const TeamBoardSetting = lazy(() =>
  import('./pages').then((m) => ({ default: m.TeamBoardSetting })),
);
const Tasks = lazy(() => import('./pages').then((m) => ({ default: m.Tasks })));

export const TaskAndApprovalRoutes = ({ burger }) => (
  <>
    <Route
      exact
      path="/settings/tasks-settings"
      element={<TasksSettings burger={burger} />}
    />
    <Route
      exact
      path="/tasks/team-setting"
      element={<TeamBoardSetting burger={burger} />}
    />
    <Route path="/tasks" element={<Tasks burger={burger} />} />
    <Route
      exact
      path={urls.central.approval.base}
      element={<Approvals burger={burger} />}
    />
    <Route
      exact
      path={urls.central.approval.settings}
      render={() => <ApprovalProcess burger={burger} />}
    />
    {/* <Route
      exact
      path="/approvals/my-requests"
      element={<MyRequests burger={burger} />}
    /> */}
  </>
);
