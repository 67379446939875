import { callApi } from 'helper/call-api';

class FinanceApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`;
  // }

  getListFeebook = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/list`,
      params,
    });
  };

  getListScholarship = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship`,
      params,
    });
  };

  getListAcademic = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/academic/list`,
      params,
    });
  };

  getListProgram = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/admission/program/list`,
      params,
    });
  };

  createFeebook = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks`,
      params,
      data,
    });
  };

  updateFeebookById = async (data, feebookId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/${feebookId}`,
      data,
    });
  };

  getFeebookById = async (feebookId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/${feebookId}`,
    });
  };

  sendForApprovalFeebook = async (feeBookId) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/send-for-approval/${feeBookId}`,
      data: {},
    });
  };

  getListTerm = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/academic/list-term`,
      params,
    });
  };

  getListScholarship1 = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship/list`,
      params,
    });
  };
}

const financeApi = new FinanceApi();
export default financeApi;
