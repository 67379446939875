import React from 'react';
import { dodles, logoLogin } from 'static/image';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import './index.scss';
import { icsettings, icuser, icusergroup } from '../image';

const ACCOUNT_TYPE = [
  {
    title: 'Teacher account',
    subtitle: 'Educator, campus admin, IT specialist',
    image: icuser,
    url: '/login/user?loginTypeId=2',
  },
  {
    title: 'Parent account',
    subtitle: 'Family member',
    image: icusergroup,
    url: '/login/user?loginTypeId=3',
  },
  {
    title: 'Global admin',
    subtitle: 'Master admin, school admin etc.',
    image: icsettings,
    url: '/login/user?loginTypeId=1',
  },
];

const AccountTypeHolder = ({ image, title, subtitle, url, handleClick }) => {
  return (
    <div
      className="w-full rounded border-2 border-light cursor-pointer p-4 h-20 flex items-center"
      onClick={() => handleClick(url)}
    >
      <div className="flex flex-row gap-x-4 items-center">
        <img src={image} alt={title} className="w-10 h-10" />
        <div>
          <p className="text-sm font-medium">{title}</p>
          <p className="text-second-gray font-normal mt-1 text-xs">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

const AccountTypes = () => {
  const navigate = useNavigate();
  const handleClickTypes = (url) => {
    navigate(url);
  };

  return (
    <div className="login account-type">
      <div className="m-auto">
        <div className="mb-4 w-full">
          <a href="/">
            <img src={logoLogin} alt="logo" className="m-auto" />
          </a>
        </div>
        <Fade clear duration={300}>
          <div className="box pb-8">
            <h1 className="text-3xl font-black">Welcome</h1>
            <p className="text-second-gray font-normal text-base mt-1">
              {'Choose an account type to proceed.'}
            </p>
            <div className="flex mt-5 pr-8">
              <div className="flex flex-col grow gap-y-5 pr-4">
                {ACCOUNT_TYPE.map((item, index) => {
                  return (
                    <AccountTypeHolder
                      key={index}
                      image={item.image}
                      title={item.title}
                      subtitle={item.subtitle}
                      url={item.url}
                      handleClick={handleClickTypes}
                    />
                  );
                })}
              </div>
              <div>
                <img src={dodles} alt="dodles" className="dodles" />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

AccountTypes.propTypes = {};

export default AccountTypes;
