import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';
import { dashboard, icsettings, icuser, verified } from 'static/image';
import { useAuth } from '@core/pages/Login/components/UserLogin/firebaseSignin';

const dataLinksDefault = [
  { img: dashboard, name: 'Dashboard', link: '/' },
  { img: verified, name: 'Application', link: '/application/application' },
  { img: icsettings, name: 'Change Password', link: '/settings/change-password' },
  { img: icsettings, name: 'Settings', link: '/settings/user-settings' },
  { img: icuser, name: 'Admin', link: '/' },
];

export default function Profile({
  visible,
  onClosed,
  userLogin,
  dataLinks = dataLinksDefault,
}) {
  const { signOut } = useAuth();

  return (
    <Zoom duration={300} when={visible}>
      <div
        className={clsx(
          'profile-box-card',
          'absolute pt-4 pb-4 top-14 right-0',
          visible ? '' : 'hidden',
        )}
      >
        <div className="border-b p-4 pl-5 ">
          <p className="text-main-black text-base font-semibold leading-4">
            {userLogin?.displayName}
          </p>
          <p className="text-second-gray text-sm">{userLogin?.email}</p>
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          {dataLinks.map((item, key) => (
            <NavLink
              onClick={onClosed}
              key={key}
              to={item.link}
              className="profile-box-card_link"
            >
              <img
                src={item.img}
                alt={item.name}
                className="relative mr-1.5 h-6 w-6"
                style={{ bottom: 1 }}
              />
              {item.name}
            </NavLink>
          ))}
          <button
            onClick={signOut}
            className="flex items-center justify-center font-semibold border rounded-lg w-52 h-9 mt-4"
          >
            Logout
          </button>
        </div>
      </div>
    </Zoom>
  );
}

Profile.propTypes = {
  visible: PropTypes.bool,
  onCLosed: PropTypes.func,
};
