import { memo } from 'react';
import './SingleCampus.scss';
import { defaultAvatar } from 'utils/constant';

const SingleCampus = memo((props) => {
  const { campus, onClick } = props;
  return (
    <div id="navbar-single-campus">
      <div className="basic-panel-card" onClick={() => onClick(campus)}>
        <img
          className="basic-panel-card-avatar"
          src={campus.logo || defaultAvatar}
          alt="campus"
          loading="lazy"
        />
        <div className="basic-panel-menu_text">
          <div className="basic-panel-menu_text-start">
            <p title={campus.name}>{campus.name}</p>
          </div>
          <div className="basic-panel-menu_text-end">
            <p>
              {campus.city}, {campus.state}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
SingleCampus.displayName = 'SingleCampus';
export default SingleCampus;
