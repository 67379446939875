import { memo } from 'react';
import useStorage from 'store/storage';
import './SwitchCampus.scss';
import { defaultAvatar } from 'utils/constant';
const SwicthCampus = memo((props) => {
  const { onClick, openPopup } = props;
  const campus = useStorage((state) => state.getCurrentCampus());
  if (!campus) return null;
  const cityState = `${campus?.city}, ${campus?.state}`;
  return (
    <div id="navbar-switch-campus">
      <div className="basic-panel-card">
        <img
          className="basic-panel-card-avatar w-10"
          src={campus?.logo || defaultAvatar}
          alt="campus"
          loading="lazy"
        />
        <div className="basic-panel-menu_text">
          <div className="basic-panel-menu_text-start">
            <p title={campus?.name}>{campus?.name}</p>
            <div className="basic-panel-menu_text-end">
              <p title={cityState}>{cityState}</p>
            </div>
          </div>
          <div
            className={`${openPopup ? 'rotate-180' : ''} basic-panel-card-triangle`}
            onClick={onClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8705 7.29L3.67652 15.5115C2.82752 16.482 3.51752 18 4.80602 18H19.194C19.4824 18.0003 19.7647 17.9174 20.0072 17.7613C20.2497 17.6052 20.442 17.3825 20.5612 17.12C20.6804 16.8574 20.7213 16.566 20.6792 16.2807C20.637 15.9955 20.5136 15.7284 20.3235 15.5115L13.1295 7.2915C12.9887 7.13039 12.8151 7.00126 12.6203 6.91278C12.4255 6.82431 12.214 6.77853 12 6.77853C11.7861 6.77853 11.5746 6.82431 11.3798 6.91278C11.1849 7.00126 11.0113 7.13039 10.8705 7.2915V7.29Z"
                fill="#000"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
});
SwicthCampus.displayName = 'SwicthCampus';
export default SwicthCampus;
